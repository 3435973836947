import * as React from "react";
import { Text, View, StyleSheet, Dimensions } from "react-native";
import { useTemplate } from "../../context/templateContext";

export default function Background() {
  const colors = useTemplate().colors;
  return (
    <View
      style={[styles.mainBackground, { borderTopColor: colors.mainColor }]}
      resizeMode="contain"
    ></View>
  );
}

const styles = StyleSheet.create({
  mainBackground: {
    position: "absolute",
    width: Math.max(
      Dimensions.get("screen").width,
      Dimensions.get("screen").height
    ),
    height: Math.max(
      Dimensions.get("window").width,
      Dimensions.get("window").height
    ),
    borderTopWidth: Dimensions.get("window").height * 0.3,
  },
});
