import * as React from "react";
import { useState, useEffect, memo } from "react";
import { Text, StyleSheet, View, TouchableOpacity } from "react-native";
import { Row, ButtonIcon, Column } from "../../components/baseUI";
import { getWordTypeName } from "../addEditCart/staticValuesCart";
import { useSelector, useDispatch } from "react-redux";
import {
  showCartDetail,
  hideCartDetail,
  cartDetailState,
} from "../../redux/todos/cartDetailSlice";
import { speak } from "../../utility/speech";

const CartItem = ({ item, query, colors }) => {
  // console.log("CartItem " + item.word);
  const dispatch = useDispatch();

  return (
    <TouchableOpacity
      styleButton={{ min: "100%" }}
      onPress={() => {
        dispatch(
          showCartDetail({ value: { formData: item, query }, visible: true })
        );
      }}
      title={""}
      color={"#ffffff00"}
      accessibilityLabel={""}
    >
      <Column style={styles.itemWordFamily}>
        <Row style={{ width1: "100%" }}>
          <Text style={[styles.itemWord, { color: colors.black }]}>
            {item.word}
          </Text>
          <ButtonIcon
            style={styles.itemWordFamilyBtn}
            color={colors.black}
            title={"  "}
            onPress={() => {
              speak(item.word);
            }}
          />
        </Row>

        <Row style={{ paddingTop: 5 }}>
          <Text style={[styles.itemWord, { color: colors.black }]}>
            {getWordTypeName(item.type) + " "}
          </Text>
          <Text style={styles.itemWord}>{item.tags}</Text>
        </Row>
      </Column>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  itemWordFamily: {
    padding: 5,
    marginVertical: 5,
    marginHorizontal: 5,
    justifyContent: "space-between",
    height: 75,
  },

  itemWord: {
    fontSize: 18,
    justifyContent: "space-around",
  },

  itemWordFamilyBtn: {
    paddingTop: 3,
    fontSize: 16,
  },
});

export default memo(CartItem);
