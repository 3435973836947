import { createSlice } from '@reduxjs/toolkit';

export const cartDetailSlice = createSlice({
    name: 'CartDetailSlice',
    initialState: {
        visible: false,
        value:[]
    },
    reducers: {
        showCartDetail: (state, { payload }) => {
            state.visible = payload.visible;
            state.value = payload.value; 
        },
        hideCartDetail: (state) => {
            state.visible = false;
        }
    },
});

export const { showCartDetail, hideCartDetail } = cartDetailSlice.actions;

export const cartDetailState = (state) => state.cartDetailSlice;

export default cartDetailSlice.reducer
