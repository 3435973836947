import * as React from "react";
import {
  Text,
  View,
  StyleSheet,
  Modal as ModalReactNative,
  TouchableOpacity,
} from "react-native";
import Desktop from "../ui/Desktop";

export default function ModalScreen({ children, visible, onRequestClose }) {
  return (
    <View style={[styles.body_View]}>
      <View>
        <ModalReactNative
          animationType="slide"
          transparent={true}
          visible={visible}
          onRequestClose={() => {
            onRequestClose();
          }}
        >
          <Desktop>
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <TouchableOpacity
                  style={[styles.buttonClose]}
                  onPress={() => {
                    onRequestClose();
                  }}
                >
                  <Text style={styles.titleButtonClose}>  </Text>
                </TouchableOpacity>
                <View style={{ width: "100%" }}>{children}</View>
              </View>
            </View>
          </Desktop>
        </ModalReactNative>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#1d313fd9",
  },
  modalView: {
    margin: 16,
    backgroundColor: "white",
    //borderRadius: 20,
    //padding: 20,
    alignItems: "flex-start",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    overflow: "hidden",
    width: "90%",
  },
  buttonClose: {
    padding: 9,
    backgroundColor: "#2196F3",
    marginTop: -40,
    borderRadius: 8,
  },
  titleButtonClose: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "fontello",
    fontSize: 18,
  },
});
