import * as React from "react";
import { Text, StyleSheet } from "react-native";
import { useTemplate } from "../../context/templateContext";

export default function Label({ children, style }) {
  const colors = useTemplate().colors;
  return (
    <Text
      style={[styles.label, { color: colors.textGray }, style]}
    >
      {children}
    </Text>
  );
}

const styles = StyleSheet.create({
  label: {
    fontSize: 20,
  },
});
