import * as React from "react";
import { useState, useEffect } from "react";
import {
  Text,
  View,
  StyleSheet,
  TextInput as Input,
  TouchableOpacity,
} from "react-native";

export default function TagsInput({
  value,
  placeholder,
  onChangeText,
  onSearch,
  itemComponents,
  colors,
}) {
  const templateStyle = {
    inputBackgroundColor: colors?.inputBackgroundColor || "#ffffff",
    inputBorderColor: colors?.inputBorderColor || "#C4C4C4",
    inputPlaceholderColor: colors?.inputPlaceholderColor || "#A8A3B4",
    inputColor: colors?.inputColor || "#111111",
  };

  const [tags, setTags] = useState(value || "");
  const [position, setPosition] = useState({
    visible: false,
    height: 0,
    width: 0,
    left: 0,
    top: 0,
    x: 0,
    y: 0,
  });

  const [wordSelected, setWordSelected] = useState({
    start: 0,
    end: 0,
    text: "",
  });

  const [selection, setSelection] = React.useState({ start: 0, end: 0 });
  const [result, setResult] = useState([]);

  useEffect(() => {
    setTags(value);
  }, [value]);

  const Tags_onKeyPress = (input) => {
    let text = tags.replace(/\#/g, " #").replace(/  \#/g, " #").trim();
    if (input.code === "Space") {
      text += "#";
      setTags(text);
    }
  };

  const Tags_onChangeText = (text) => {
    text = text.replace(/\#/g, " #").replace(/  \#/g, " #").trim();
    setTags(text);
    if (onChangeText) onChangeText(text);
  };

  const Tags_onSelectionChange = async (selection) => {
    let wordSelected = FindWord(tags, selection);
    setSelection(selection);
    setWordSelected(wordSelected);

    if (onSearch) {
      setResult([]);
      let list = await onSearch(wordSelected);
      setResult(list);
    }
  };

  const FindWord = (text, selectionText) => {
    for (var i = selectionText.start; i > -1; i--) {
      if (text[i] == "#") {
        let findHash = text.indexOf("#", i + 1) - 1;
        let word = "";
        if (findHash < 0) {
          findHash = text.length;
        }
        word = text.substring(i, findHash);
        return { start: i + 1, end: findHash - 0, text: word };
      }
    }
  };

  const setListVisible = (visible) => {
    setPosition({ ...position, visible: visible });
  };

  return (
    <>
      <Input
        selection={selection}
        onSelectionChange={async ({ nativeEvent: { selection } }) => {
          await Tags_onSelectionChange(selection);
        }}
        style={[
          styles.textInput,
          {
            backgroundColor: templateStyle.inputBackgroundColor,
            borderColor: templateStyle.inputBorderColor,
          },
          {
            color: value
              ? templateStyle.inputColor
              : templateStyle.inputPlaceholderColor,
          },
        ]}
        placeholder={placeholder}
        onChangeText={Tags_onChangeText}
        onKeyPress={Tags_onKeyPress}
        value={tags}
        //defaultValue={tags}
        onLayout={({ nativeEvent: { layout } }) => {
          setPosition({ ...layout });
          console.log(JSON.stringify(layout));
        }}
        onBlur={() => {
          setTimeout(() => {
            setPosition({ ...position, visible: false });
          }, 500);
        }}
        onFocus={() => {
          setPosition({ ...position, visible: true });
        }}
      />
      {position.visible ? (
        <View
          style={[
            styles.box,
            { top: position.height + position.y, width: position.width },
          ]}
        >
          {
            // <Text>
            //   {JSON.stringify(result)}
            //   {JSON.stringify(wordSelected)}
            // </Text>
          }
          {result == null ? (
            <>
              <TouchableOpacity>
                <Text>Not Find</Text>
              </TouchableOpacity>
            </>
          ) : result.length == 0 ? (
            <>
              <TouchableOpacity>
                <Text>Loading</Text>
              </TouchableOpacity>
            </>
          ) : (
            result.map((item, i) => {
              return (
                <View key={i} style={{}}>
                  {itemComponents ? (
                    itemComponents(item, wordSelected, setListVisible)
                  ) : (
                    <></>
                  )}
                </View>
              );
            })
          )}
        </View>
      ) : (
        <></>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  textInput: {
    backgroundColor: "#fff",
    borderColor: "#C4C4C4",
    borderWidth: 1,
    borderRadius: 5,
    height: 40,
    paddingLeft: 6,
  },
  textInput_text: {
    color: "#111",
  },
  textInput_placeholder: {
    color: "#A8A3B4",
  },
  box: {
    position: "absolute",
    flexDirection: "row",
    flexWrap: "wrap",
    overflow: "scroll",
    backgroundColor: "#EFEFEF",
    minHeight: 130,
    maxHeight: 130,
    minWidth: 50,

    borderColor: "#C4C4C4",
    borderWidth: 1,
    borderRadius: 5,
    zIndex: 1,
    elevation: 1,
  },
});
