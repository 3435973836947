import { createSlice } from '@reduxjs/toolkit';

export const searchBoxAllCartSlice = createSlice({
    name: 'searchBoxAllCartSlice',
    initialState: {
        visible: false,
    },
    reducers: {
        setVisibleBoxAllCart: (state, { payload }) => {
            state.visible = payload; 
        }
    },
});

export const { setVisibleBoxAllCart } = searchBoxAllCartSlice.actions;

export const searchBoxAllCartState = (state) => state.searchBoxAllCartSlice;

export default searchBoxAllCartSlice.reducer
