import React, { useLayoutEffect, useState, useEffect, useMemo } from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Linking, Dimensions } from "react-native";
import { Page, Body, Box, Container, ButtonIcon, Button, Row, Label, TextInput } from '../../components/baseUI';
import ListCart from ".";
import * as RootNavigation from '../../components/ui/Navigator';

export default function SearchCartScreen({ navigation }) {

    // useLayoutEffect(() => {
    //     if (navigation) {
    //         navigation.setOptions({
    //             headerRight: () => (
    //                 <View
    //                     style={{ flexDirection: "row", paddingRight: 10, paddingTop: 10 }}
    //                 >
    //                     <ButtonIcon
    //                         title={""}
    //                         color={"#fff"}
    //                         fontSize={32}
    //                         style={{ paddingLeft: 10 }}
    //                         onPress={() => {
    //                             RootNavigation.navigation.navigate({ name: 'AddEditCartScreen', params: {} });
    //                         }}
    //                     />
    //                 </View>
    //             ),
    //         });
    //     }
    // }, [navigation]);

    return (
        <ListCart navigation={navigation} />
    );
}

