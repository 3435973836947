import React, { useLayoutEffect, useState, useEffect, useMemo } from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { useInfiniteQuery, useQueryClient } from "react-query";
import {
  Page,
  Body,
  Box,
  Container,
  ButtonIcon,
  TextInput,
} from "../../components/baseUI";
import Toast from "../../components/ui/Toast";
import Loading from "../../components/ui/Loading";
import Background from "../../components/ui/Background";
import ErrorButton from "../../components/ui/ErrorButton";

import { listCarts } from "../../services/cart";
import { cartDetailState } from "../../redux/todos/cartDetailSlice";

import PageSearchList from "./pageSearchList";
import CartModal from "./cartDetailsModal";
import AddEditCartModal from "../addEditCart/addEditCartModal";
import { useTemplate } from "../../context/templateContext";

export default function SearchCart({ navigation }) {
  console.log("index > SearchCart");
  const colors = useTemplate().colors;

  if (navigation) {
    useLayoutEffect(() => {
      navigation.setOptions({
        headerRight: () => (
          <View
            style={{ flexDirection: "row", paddingRight: 10, paddingTop: 10 }}
          >
            <ButtonIcon
              title={""}
              color={colors.menuWhite}
              fontSize={32}
              style={{ paddingLeft: 10 }}
              onPress={() => {
                //RootNavigation.navigation.navigate({ name: 'AddEditCartScreen', params: {} });
                setModalFamilyWord({
                  visible: true,
                  value: { pageId: 0, pageParentId: 0 },
                });
              }}
            />
          </View>
        ),
      });
    }, [navigation,colors]);
  }

  const queryClient = useQueryClient();

  const [toastState, setToast] = useState({
    visible: false,
    message: "",
    time: 4000,
  });

  const cartDetail = useSelector(cartDetailState);
  const [modalFamilyWord, setModalFamilyWord] = useState({
    visible: false,
    value: {},
  });

  const [errorLoadState, setErrorLoadState] = useState({
    visible: false,
    message: "",
  });
  const [filter, setFilter] = useState({ q: "", visible: false });

  const { status, data, isFetching, isLoading, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ["searchCartQuery", { ...filter, timeToShow: false }],
      queryFn: listCarts,
      select: (data) => {
        let items = [];
        data.pages?.forEach((itm) => {
          items = [...items, ...itm.result.data];
        });
        return {
          items: items.filter((m) => m.isDelete != true),
          query: ["searchCartQuery", filter],
        };
      },
      onError: (error) => {
        setToast({ message: "🤨 " + error.message, time: 4000 });
        setErrorLoadState({ visible: true, message: error.message });
      },
      onSuccess: () => {
        setErrorLoadState({ ...errorLoadState, visible: false });
      },
      getNextPageParam: (lastPage, pages) => {
        return lastPage.result.pageInfo.page <
          lastPage.result.pageInfo.totalPages
          ? lastPage.result.pageInfo.page + 1
          : undefined; // If there is not a next page, getNextPageParam will return undefined and the hasNextPage boolean will be set to 'false'
      },
    });

  return (
    <Page>
      <Background></Background>
      <Body scrollable={false}>
        <Box>
          <Container>
            <TextInput
              colors={colors}
              placeholder={"Search all"}
              value={filter.q}
              onChangeText={(text) => {
                queryClient.removeQueries(["searchCartQuery", filter]);
                setFilter({ ...filter, q: text });
              }}
            />
          </Container>
        </Box>

        <Box>
          <Container>
            <View style={{ maxHeight: Dimensions.get("window").height - 200 }}>
              {data ? ( //
                <>
                  <PageSearchList
                    data={data}
                    fetchNextPage={fetchNextPage}
                    isFetching={isFetching}
                  />{" "}
                </>
              ) : (
                <></>
              )}
            </View>
          </Container>
        </Box>
      </Body>

      <CartModal
        value={cartDetail.value}
        visible={cartDetail.visible}
        onRequestClose={(closeType, value) => {
          setModalCart({ value: { id: "" }, visible: false });
        }}
        changeForm={({ item }) => {
          setModalCart({ ...modalCart, visible: false });
        }}
      />

      <AddEditCartModal
        value={modalFamilyWord.value}
        visible={modalFamilyWord.visible}
        onRequestClose={(closeType, value) => {
          setModalFamilyWord({ value: { id: "" }, visible: false });
        }}
        changeForm={({ item, isAdding }) => {
          debugger;
          setModalFamilyWord({ ...modalFamilyWord, visible: false });
          // queryClient.setQueryData(['searchCartQuery', filter], (old) => {
          //     let result = { ...old };
          //     result.pages[result.pages.length - 1].result.data = [item, ...item.relatedCards, ...result.pages[result.pages.length - 1].result.data];
          //     return result;
          // });
        }}
        closeOnPress={() => {
          setModalFamilyWord({ value: { id: "" }, visible: false });
        }}
      />

      <Loading visible={isFetching || isLoading} />

      <Toast
        {...toastState}
        onHide={() => {
          setToast({ visible: false });
        }}
      />

      <ErrorButton
        visible={errorLoadState.visible}
        message={errorLoadState.message}
        onPress={() => {
          queryClient.refetchQueries(["searchCartQuery", filter]);
          setErrorLoadState({ ...errorLoadState, visible: false });
        }}
      />
    </Page>
  );
}

const styles = StyleSheet.create({
  headerButton: {
    fontFamily: "fontello",
    fontSize: 32,
    color: "#fff",
  },
  menu: {
    fontFamily: "fontello",
    fontSize: 20,
    color: "#444",
  },
  paragraph: {
    fontFamily: "fontello",
    textAlign: "center",
  },
  scroll: {
    height: 90,
  },
  item: {
    padding: 5,
    marginVertical: 5,
    marginHorizontal: 5,
    justifyContent: "space-between",
  },
  footer: {
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
});
