import * as React from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";

export default function CheckBox({
  title,
  value = false,
  color = "#444",
  fontSize = 18,
  index,
  accessibilityLabel,
  style,
  styleText,
  onPress,
}) {
  return (
    <TouchableOpacity
      styleButton={[{ width: "100%", flex: 1, backgroundColor: "red" }]}
      onPress={() => {
        if (onPress) {
          onPress({ value, index });
        }
      }}
      title={title || ""}
      color={"#ffffff00"}
      accessibilityLabel={accessibilityLabel || ""}
    >
      <View
        style={[
          {
            flexDirection: "row",
            flex: 1,
            justifyContent: "space-between",
            width: "100%",
          },
          style,
        ]}
      >
        <Text style={[styles.checkBoxIcon, { color: color }]}>
          {value ? "" : ""}
        </Text>
        <Text
          style={[
            styles.checkBoxTitle,
            { color: color, fontSize: fontSize },
            styleText,
          ]}
        >
          {title || ""}
        </Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  checkBoxIcon: {
    fontFamily: "fontello",
    fontSize: 22,
  },
  checkBoxTitle: {
    //fontFamily: 'fontello'
  },
});
