import * as React from 'react';
import { Text, StyleSheet } from 'react-native';


export default function LabelIcon({ children, style }) {
  return (
    <Text style={[styles.label, style]}>
      {children}
    </Text>
  );
}

const styles = StyleSheet.create({
  label: {
    fontFamily: 'fontello',
    //color: '#5B5969',
    fontSize: 20,
  },
});


