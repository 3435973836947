import React, { useRef, useEffect, useState } from 'react';
import {
  View,
  StyleSheet,
  Animated,
  ActivityIndicator,
} from "react-native";
import { Dimensions } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

export default function Loading({visible}) {
  const showAnimated = useRef(new Animated.Value(0)).current;
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (visible) {
      Animated.parallel([
        Animated.timing(showAnimated, {
          toValue: 1,
          duration: 500,
        }),
      ]).start();
      setDisplay(true);
    } else {
      Animated.parallel([
        Animated.timing(showAnimated, {
          toValue: 0,
          duration: 500,
        }),
      ]).start(() => {
        setDisplay(false);
      });
    }
  }, [visible]);

  return (
    <Animated.View style={[styles.centeredView, { top: '40%', opacity: showAnimated, display: !display ? 'none' : 'flex' }]}>
      <View style={[styles.modalView, { minHeight: 40 }]}>
        <ActivityIndicator size={"large"} />
      </View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    top: "80%",
    minHeight: '10%',
    width: '100%',
    position: 'absolute',
    //backgroundColor: '#f0f',
    zIndex: 90000000,
    elevation: 9000000
  },
  modalView: {
    "shadowOffset": {
      "width": 1,
      "height": 1
    },
    "shadowOpacity": 0.5,
    "shadowRadius": 17.084375,
    margin: 16,
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    elevation: 5,
    overflow: "hidden",
    width: 50,
    height: 50,
    borderRadius: 30,
    padding: 5
  },
  text: {
    padding: 8,
    fontSize: 20
  },
});
