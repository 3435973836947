import { useQuery } from "react-query";
import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const updateUserProfile = async () => {
  return new Promise((resolve, reject) => {
    try {
      axios.get(`auth/login/success`).then((resultText) => { 
        let user = resultText.data.user;
        setUserProfile(user).then(() => {
          resolve(user);
        });
      });
    } catch (err) { 
      console.log("error updateUserProfile");
      reject();
    }
  });
};

export const setUserProfile = async (user) => {
  return new Promise((resolve, reject) => {
    try {
      AsyncStorage.setItem("auth", JSON.stringify(user)).then(() => {
        resolve();
      });
    } catch (err) { 
      console.log("error setUserProfile");
      reject();
    }
  });
};

export const getUserProfile = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await AsyncStorage.getItem("auth");
      res = JSON.parse(res);
      if (res === null) {
        reject();
        return;
      }
      resolve(res);
    } catch (err) { 
      reject();
      console.log("error getUserProfile");
    }
  });
};

export const clearUserProfile = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      await AsyncStorage.setItem("auth", "");
    } catch (err) { 
      reject();
    }
  });
};
