import * as React from "react";
import { StyleSheet, TextInput as Input } from "react-native";

export default function TextInput({
  value,
  placeholder,
  onChangeText,
  colors,
}) {
  const templateStyle = {
    inputBackgroundColor: colors?.inputBackgroundColor || "#ffffff",
    inputBorderColor: colors?.inputBorderColor || "#C4C4C4",
    inputPlaceholderColor: colors?.inputPlaceholderColor || "#A8A3B4",
    inputColor: colors?.inputColor || "#111111",
  };

  return (
    <Input
      style={[
        styles.textInput,
        {
          backgroundColor: templateStyle.inputBackgroundColor,
          borderColor: templateStyle.inputBorderColor,
        },
        {
          color: value
            ? templateStyle.inputColor
            : templateStyle.inputPlaceholderColor,
        },
      ]}
      placeholder={placeholder}
      onChangeText={(newText) => {
        if (onChangeText) {
          onChangeText(newText);
        }
      }}
      defaultValue={value}
      placeholderStyle={styles.placeholderStyle}
    />
  );
}

const styles = StyleSheet.create({
  textInput: {
    borderWidth: 1,
    borderRadius: 5,
    height: 40,
    paddingLeft: 6,
  },
});
