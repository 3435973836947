import * as React from 'react';
import { Text, StyleSheet, TouchableOpacity } from 'react-native';


export default function ButtonIcon({ title, color = '#444', fontSize = 18, accessibilityLabel, style, onPress }) {
    return (
        <TouchableOpacity
            styleButton={style}
            onPress={onPress}
            title={title || ""}
            color={"#ffffff00"}
            accessibilityLabel={accessibilityLabel || ""}
        >
            <Text style={[style,styles.ButtonIcon, { color: color, fontSize: fontSize }]}>
                {title || ""}
            </Text>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    ButtonIcon: {
        fontFamily: 'fontello'
    },
});


