import * as React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { Page, Body, Footer, Box, Container, ButtonIcon, Button, Row } from '../../components/baseUI';
import Home from '.';
import * as RootNavigation from '../../components/ui/Navigator';
import { useSelector, useDispatch } from 'react-redux';


export default function HomeScreen({ navigation }) {

  return (
    <Home/> 
  );
}

 