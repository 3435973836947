import React, { useLayoutEffect, useState, useEffect, useMemo } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Linking,
  Dimensions,
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { useInfiniteQuery, useQueryClient } from "react-query";
import {
  Page,
  Body,
  Box,
  Container,
  ButtonIcon,
  Button,
  Row,
  Label,
  TextInput,
} from "../../components/baseUI";
import Toast from "../../components/ui/Toast";
import Loading from "../../components/ui/Loading";
import Background from "../../components/ui/Background";
import ErrorButton from "../../components/ui/ErrorButton";

import { listCarts } from "../../services/cart";
import { cartDetailState } from "../../redux/todos/cartDetailSlice";

import PageSearchList from "./pageSearchList";
import AddEditCartModal from "../addEditCart/addEditCartModal";

import * as RootNavigation from "../../components/ui/Navigator";
import { closeMenu } from "../../redux/todos/mainMenuSlice";
import { clearUserProfile } from "../../services/authorization";
import { useTemplate } from "../../context/templateContext";

import {
  setCountOfCart,
  setCountOfReadyCart,
} from "../../redux/todos/leitnerInfoBoxSlice";

export default function LeitnerBox({ navigation }) {
  console.log("index > ListCart");
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const colors = useTemplate().colors;
  const [filter, setFilter] = useState({ q: "", visible: false });
  //const cartInfo = {setCountOfCart, setCountOfReadyCart};

  if (navigation) {
    useLayoutEffect(() => {
      navigation.setOptions({
        headerRight: () => (
          <View
            style={{ flexDirection: "row", paddingRight: 10, paddingTop: 10 }}
          >
            <ButtonIcon
              title={""}
              color={colors.menuWhite}
              fontSize={32}
              style={{ paddingLeft: 10 }}
              onPress={() => {
                setFilter({ ...filter, visible: !filter.visible });
              }}
            />

            <ButtonIcon
              title={""}
              color={colors.menuWhite}
              fontSize={32}
              style={{ paddingLeft: 10 }}
              onPress={() => {
                setModalFamilyWord({
                  visible: true,
                  value: { pageId: 0, pageParentId: 0 },
                });
              }}
            />
          </View>
        ),
      });
    }, [navigation, filter.visible,colors]);
  }

  const [toastState, setToast] = useState({
    visible: false,
    message: "",
    time: 4000,
  });

  const cartDetail = useSelector(cartDetailState);
  const [modalFamilyWord, setModalFamilyWord] = useState({
    visible: false,
    value: {},
  });

  const [errorLoadListCartState, setErrorLoadListCartState] = useState({
    visible: false,
    message: "",
  });

  const { status, data, isFetching, isLoading, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ["listCartQuery", { ...filter, timeToShow: true }],
      queryFn: async (args) => {
        const response = await listCarts(args);
        const cartInfo = response.result.cartInfo;
        dispatch(setCountOfCart(cartInfo.countOfCart));
        dispatch(setCountOfReadyCart(cartInfo.countOfReadyCart));
        return response;
      },
      select: (data) => {
        let items = [];
        data.pages?.forEach((itm) => {
          items = [...items, ...itm.result.data];
        });
        return {
          //isReadyToStudy
          items: items.filter(
            (m) => m.isDelete != true && m.isReadyToStudy == true
          ),
          query: ["listCartQuery", filter],
        };
      },
      onError: async (error) => {
        setToast({ message: "🤨 " + error.message, time: 4000 });
        setErrorLoadListCartState({ visible: true, message: error.message });

        if (error.message == "Authorized response was not ok") {
          await clearUserProfile();
          setTimeout(() => {
            RootNavigation.navigation.navigate({
              name: "LoginScreen",
              params: { userName: "LoginScreen" },
            });
            dispatch(closeMenu());
          }, 2000);
        }
      },
      onSuccess: () => {
        setErrorLoadListCartState({
          ...errorLoadListCartState,
          visible: false,
        });
      },
      getNextPageParam: (lastPage, pages) => {
        return lastPage.result.pageInfo.page <
          lastPage.result.pageInfo.totalPages
          ? lastPage.result.pageInfo.page + 1
          : undefined; // If there is not a next page, getNextPageParam will return undefined and the hasNextPage boolean will be set to 'false'
      },
    });

  return (
    <Page>
      <Background></Background>
      {filter.visible ? (
        <View style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Box>
            <Container>
              <TextInput
                placeholder={"Search ..."}
                value={filter.q}
                colors={colors}
                onChangeText={(text) => {
                  queryClient.removeQueries(["listCartQuery", filter]);
                  setFilter({ ...filter, q: text });
                }}
              />
            </Container>
          </Box>
        </View>
      ) : (
        <></>
      )}

      <View
        style={{
          maxHeight:
            Dimensions.get("window").height - (filter.visible ? 150 : 0),
        }}
      >
        {data ? (
          <>
            <PageSearchList
              data={data}
              fetchNextPage={fetchNextPage}
              isFetching={isFetching}
            />{" "}
          </>
        ) : (
          <></>
        )}
      </View>

      <AddEditCartModal
        value={modalFamilyWord.value}
        visible={modalFamilyWord.visible}
        onRequestClose={(closeType, value) => {
          setModalFamilyWord({ value: { id: "" }, visible: false });
        }}
        changeForm={({ isAdding }) => {
          setModalFamilyWord({ ...modalFamilyWord, visible: false });
          // queryClient.setQueryData(['listCartQuery', filter], (old) => {
          //     let result = { ...old };
          //     result.pages[result.pages.length - 1].result.data = [item, ...item.relatedCards, ...result.pages[result.pages.length - 1].result.data];
          //     return result;
          // });
        }}
        closeOnPress={() => {
          setModalFamilyWord({ value: { id: "" }, visible: false });
        }}
      />

      <Loading visible={isFetching || isLoading} />

      <Toast
        {...toastState}
        onHide={() => {
          setToast({ visible: false });
        }}
      />

      <ErrorButton
        visible={errorLoadListCartState.visible}
        message={errorLoadListCartState.message}
        onPress={() => {
          queryClient.refetchQueries(["listCartQuery", filter]);
          setErrorLoadListCartState({
            ...errorLoadListCartState,
            visible: false,
          });
        }}
      />
    </Page>
  );
}
