import * as React from "react";
import { useState, useEffect } from "react";

import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import { Modal, Column, Button } from "../../components/baseUI";

export default function ColorModal({ value, visible, onRequestClose }) {
  const colors = [
    "#11111100",
    "#0d6efd",
    "#6f42c1",
    "#d63384",
    "#dc3545",
    "#fd7e14",
    "#ffc107",
    "#198754",
    "#20c997",
    "#0dcaf0",
  ];
  const [selected, setSelected] = useState(value || "#11111100");

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <Modal
      visible={visible}
      onRequestClose={() => {
        onRequestClose("Discord", selected);
      }}
    >
      <Column style={{ width: 250 }}> 
        {colors.map((item, i) => {
          return (
            <TouchableOpacity
              key={i}
              color={item}
              onPress={() => {
                setSelected(item);
              }}
            >
              <View
                style={[
                  styles.item,
                  {
                    backgroundColor: item,
                    borderColor: selected == item ? "red" : "#FFFFFF00",
                    height: 40,
                  },
                ]}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 30,
                    marginTop: 0,
                    color: "#fff",
                  }}
                >
                  {selected == item ? "✓" : ""}
                </Text>
              </View>
            </TouchableOpacity>
          );
        })}
      </Column>

      <Column style={{ flexDirection: "row" }}>
        <View style={{ flex: 1, height: 60 }}>
          <Button
            title={"Save"}
            color={"#96E66E"}
            style={styles.buttons}
            onPress={() => {
              onRequestClose("Save", selected);
            }}
          />
        </View>
      </Column>
    </Modal>
  );
}

const styles = StyleSheet.create({
  item: {
    borderWidth: 2,
    margin: 5,
  },
  buttons: {
    height: 50,
  },
});
