
import axios from "axios";
import { uniqByKeepFirst, uniqByKeepLast } from "../utility/array";

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const listCarts = async (filter) => {
  const { tags = "", limit = 15 } = filter.pageParam || {};
  let q = filter.queryKey[1]?.q || "";
  let offset = filter.pageParam || 1;
  let timeToShow = filter.timeToShow || false;
  //await sleep(1000);
  try {
    let res = await axios.get(
      `cart/carts?q=${q}&tags=${tags}&offset=${+offset}&limit=${+limit}&timeToShow=${timeToShow}`
    );
    //debugger;
    return {
      result: {
        data: res.data.carts,
        pageInfo: { totalPages: res.data.totalPages, page: offset },
        cartInfo: res.data.cartInfo
      },
    };
  } catch (err) {
    if (err.response.status === 401) {
      throw new Error("Authorized response was not ok");
    } else {
      throw new Error("Network response was not ok");
    }
  }
};

export const loadCart = async ({ id, parentId }) => {
  if (id) {
    try {
      let res = await axios.get(`cart/${id}`);
      return res.data.cart;
    } catch (err) {
      if (err.response.status === 401) {
        throw new Error("Authorized response was not ok");
      } else {
        throw new Error("Network response was not ok");
      }
    }
  } else {
    // empty
    return { parentId };
  }
};

export const cartLevel = async ({ cart }) => {
  //debugger;
  let res = await axios.put(`cart/cartLevel/`, cart);
  if (res.status === 200) {
    //await sleep(300);
    return { ...res.data.result };
  } else {
    throw {
      status: res.status,
      message: "Error occurred",
    };
  }
};


export const upsertCart = async ({ cart }) => {
  let res =
    cart.id ?
      await axios.put(`cart/`, cart)
      :
      await axios.post(`cart/`, cart);

  if (res.status === 200) {
    //await sleep(300);
    return res.data.result;
  } else {
    throw {
      status: res.status,
      message: "Error occurred",
    };
  }
};


export const listTags = async ({ q }) => {
  q = q.toLowerCase().trimLeft().replace("#", "");

  let defultsTags = [
    { title: "none", synonyms: "" },
    { title: "adjective", synonyms: "" },
    { title: "adverb", synonyms: "" },
    { title: "pronoun", synonyms: "" },
    { title: "verb", synonyms: "" },
    { title: "preposition", synonyms: "" },
    { title: "conjunction", synonyms: "" },
    { title: "interjection", synonyms: "" },
    { title: "sentence", synonyms: "" },
    { title: "clause", synonyms: "" },
    { title: "phrase", synonyms: "" },
    { title: "conjunction", synonyms: "" },
    { title: "subject", synonyms: "" },
    { title: "object", synonyms: "" },
    { title: "family", synonyms: "" },
    { title: "restaurant", synonyms: "" },
    { title: "books", synonyms: "" },
    { title: "travel", synonyms: "" },
    { title: "website", synonyms: "" },
    { title: "accident", synonyms: "" },
    { title: "childhood_memory", synonyms: "" },
    { title: "gift", synonyms: "" },
    { title: "historical_place", synonyms: "" },
    { title: "newspaper_magazine", synonyms: "" },
    { title: "memorable", synonyms: "" },
    { title: "favorite", synonyms: "" },
    { title: "museum", synonyms: "" },
    { title: "movie", synonyms: "" },
    { title: "foreign_country", synonyms: "" },
    { title: "parties", synonyms: "" },
    { title: "teacher", synonyms: "" },
    { title: "friend", synonyms: "" },
    { title: "hotel", synonyms: "" },
    { title: "letter", synonyms: "" },
    { title: "hobbies", synonyms: "" },
    { title: "music", synonyms: "" },
    { title: "shopping", synonyms: "" },
    { title: "holiday", synonyms: "" },
    { title: "animals", synonyms: "" },
    { title: "practical_skill", synonyms: "" },
    { title: "sport", synonyms: "" },
    { title: "school", synonyms: "" },
    { title: "festival", synonyms: "" },
    { title: "food", synonyms: "" },
    { title: "householdappliance", synonyms: "" },
    { title: "music", synonyms: "" },
    { title: "weather", synonyms: "" },
    { title: "neighbor", synonyms: "" },
    { title: "natural_scenery", synonyms: "" },
    { title: "outdoor_activities", synonyms: "" },
    { title: "law", synonyms: "" },
    { title: "politics", synonyms: "" },
    { title: "tv_program", synonyms: "" },
    { title: "architect_building", synonyms: "" },
    { title: "electronic_media", synonyms: "" },
    { title: "job", synonyms: "career" },
    { title: "competition_contest", synonyms: "" },
    { title: "garden", synonyms: "" },
    { title: "hometown", synonyms: "" },
    { title: "clothing", synonyms: "" },
    { title: "advertisement", synonyms: "" },
    { title: "project", synonyms: "" },
    { title: "wedding", synonyms: "" },
    { title: "coffeeshop", synonyms: "" },
    { title: "culture", synonyms: "" },
    { title: "politician", synonyms: "" },
    { title: "communication", synonyms: "" },
    { title: "business", synonyms: "" },
    { title: "computer", synonyms: "" },
    { title: "exercise", synonyms: "" },
    { title: "goal_ambition", synonyms: "" },
    { title: "art", synonyms: "" },
    { title: "fashion", synonyms: "" },
    { title: "jewelry", synonyms: "" },
    { title: "cosmetic", synonyms: "" },
    { title: "game", synonyms: "" },
    { title: "learning", synonyms: "" },
    { title: "creative", synonyms: "" },
    { title: "celebrity", synonyms: "" },
    { title: "health", synonyms: "" },
    { title: "technological", synonyms: "" },
    { title: "landmark", synonyms: "" },
    { title: "handcraft", synonyms: "" },
    { title: "surgery", synonyms: "" },
    { title: "love", synonyms: "" },
    { title: "future", synonyms: "" },
    { title: "past", synonyms: "" },
    { title: "present", synonyms: "" },
    { title: "hope", synonyms: "" },
    { title: "joy", synonyms: "" },
    { title: "sadness", synonyms: "" },
    { title: "disgust", synonyms: "" },
    { title: "fear", synonyms: "" },
    { title: "grow", synonyms: "" },
    { title: "success", synonyms: "" },
    { title: "failure", synonyms: "" },
    { title: "development", synonyms: "" },
    { title: "hesitation", synonyms: "" },
    { title: "meeting", synonyms: "sesstion" },
    { title: "transportation", synonyms: "traffic" },
    { title: "religion", synonyms: "" },
    { title: "color", synonyms: "colour" },
    { title: "money", synonyms: "" },
    { title: "address", synonyms: "" },
    { title: "describe", synonyms: "" },
    { title: "treatment", synonyms: "" },
    { title: "essay", synonyms: "" },
  ];

  let userTags = [];
  if (q.length > 0) {
    try {
      let res = await axios.get(`cart/tags?q=${q}`);
      userTags = res.data.tags;
    } catch (err) {
      debugger;
    }
  }

  let defultsTagsFiltered = [
    ...defultsTags.filter((m) => m.title.indexOf(q) != -1),
    ...defultsTags.filter((m) => m.synonyms.indexOf(q) != -1),
  ];

  defultsTagsFiltered = uniqByKeepFirst(defultsTagsFiltered, (it) => it.title);

  let allTags = [...userTags, ...defultsTagsFiltered];

  let result = uniqByKeepFirst(allTags, (it) => it.title);

  return result.length == 0 ? null : result;
};
