import React, {
  createContext,
  useState,
  useContext,
  useLayoutEffect,
} from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { ActivityIndicator } from "react-native";

import styleLite from './styleLite';
import styleDark from './styleDark';

export const TemplateContext = createContext();

export function useTemplate() {
  return useContext(TemplateContext);
}

function invertColor(hex) {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}

function darkLite(colors) {
  // return colors;
  var l = {};
  var keys = Object.keys(colors);

  for (let i = 0; i < keys.length; i++) {
    let item = keys[i];
    if (typeof colors[item] === "string") {
      let value = "#000000";
      try {
        value = invertColor(colors[item].toLowerCase());
      } catch {
        debugger;
      }
      l[item] = value;
    }
  }
  l.isDark = true;
  return l;
}


const templateProvider = ({ children }) => {
  const [colors, setColorScheme] = useState(styleLite);
  const [templateMode, setTemplateMode] = useState(0);
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    AsyncStorage.getItem("template").then((result) => {
      const templateMode = +result;
      setTemplateMode(templateMode);
      setLoading(false);
    });
  }, []);

  useLayoutEffect(() => {
    if (templateMode === 0) {
      setColorScheme(styleDark);
    } else {
      setColorScheme(styleLite); //darkLite(styleLite));
    }
  }, [templateMode]);

  const setTemplate = (template) => {
    setTemplateMode(template);
    AsyncStorage.setItem("template", template);
  };

  const getTemplate = () => {
    return templateMode;
  };

  const value = { colors, getTemplate, setTemplate };

  if (loading) {
    console.log("Loading Template");
    return (
      <>
        <ActivityIndicator color={"#555"} />
      </>
    );
  }

  return (
    <TemplateContext.Provider value={value}>
      {children}
    </TemplateContext.Provider>
  );
};

export default templateProvider;
