import * as React from "react";
import { useState, useEffect } from "react";
import { Text, View, StyleSheet, TouchableOpacity, Linking } from "react-native";
import { useDispatch } from 'react-redux';
import { useQueryClient, useMutation, useQuery } from "react-query";
import {
  Column,
  Label,
  TextInput,
  TextArea,
  ButtonIcon,
  IButton,
  Button,
  Dialog,
  TagsInput,
} from "../../components/baseUI";
import * as RootNavigation from '../../components/ui/Navigator';
import { useTemplate } from "../../context/templateContext";

import AddEditCart from ".";


export default function AddEditCartScreen({ navigation, route, changeForm, closeOnPress }) {
  var pageId = route?.params?.id;
  var pageParentId = route?.params?.parentId;
  const colors = useTemplate().colors;

  React.useLayoutEffect(() => {
    if (navigation) {
      navigation.setOptions({
        headerRight: () => (
          <View
            style={{ flexDirection: "row", paddingRight: 10, paddingTop: 10 }}
          >
            {/* <ButtonIcon
              title={""}
              color={"#fff"}
              fontSize={32}
              style={{ paddingRight: 10 }}
              onPress={() => {
                //setModalDialogDelete({ ...modalDialogDelete, visible: true });
              }}
            /> */}
            <ButtonIcon
              title={""} 
              color={colors.menuWhite}
              fontSize={32}
              style={{ paddingLeft: 10 }}
              onPress={() => {
                RootNavigation.navigation.navigate({ name: 'AddEditCartScreen', params: {} });
              }}
            />
          </View>
        ),
      });
    }
  }, [navigation]);



  return (
    <AddEditCart pageId={pageId} pageParentId={pageParentId} isModal={false} changeForm={changeForm} closeOnPress={closeOnPress} />
  );
}
