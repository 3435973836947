/* eslint-disable react/display-name */
import * as React from 'react';
import { Dimensions, StyleSheet, ScrollView, View } from 'react-native';
import Preview from '../../components/flatListSlider/Preview';
import FlatListSlider from '../../components/flatListSlider/FlatListSlider';
import { Page, Body, Footer, Box, Container, ButtonIcon, Button, Row } from '../../components/baseUI';
import Background from '../../components/ui/Background';


export default function Home() {
  const screenWidth = Math.round(Dimensions.get('window').width);
  const data = [
    {
      image:
        'https://images.unsplash.com/photo-1567226475328-9d6baaf565cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=60',
      desc: 'Silent Waters in the mountains in midst of Himilayas',
    },
    {
      image:
        'https://images.unsplash.com/photo-1455620611406-966ca6889d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1130&q=80',
      desc:
        'Red fort in India New Delhi is a magnificient masterpeiece of humans',
    },
    {
      image:
        'https://images.unsplash.com/photo-1477587458883-47145ed94245?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
      desc:
        'Sample Description below the image for representation purpose only',
    },
    {
      image:
        'https://images.unsplash.com/photo-1568700942090-19dc36fab0c4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
      desc:
        'Sample Description below the image for representation purpose only',
    },
    {
      image:
        'https://images.unsplash.com/photo-1584271854089-9bb3e5168e32?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80',
      desc:
        'Sample Description below the image for representation purpose only',
    },
  ];

  return (
    <Page>
      <Background></Background>
      <Body>
        <Box>
          <Container>
            <ScrollView>
              <FlatListSlider
                data={data}
                timer={20000}
                imageKey={'image'}
                local={false}
                width={screenWidth}
                separator={0}
                loop={true}
                autoscroll={true}
                currentIndexCallback={index => console.log('Index', index)}
                onPress={item => alert(JSON.stringify(item))}
                indicator
                animation
              />
              <View style={styles.separator} />
              <FlatListSlider
                data={data}
                width={275}
                timer={40000}
                component={<Preview />}
                onPress={item => alert(JSON.stringify(item))}
                indicatorActiveWidth={40}
                contentContainerStyle={styles.contentStyle}
              />
              <View style={styles.separator} />
              <FlatListSlider
                data={data}
                timer={50000}
                onPress={item => alert(JSON.stringify(item))}
                indicatorContainerStyle={{ position: 'absolute', bottom: 20 }}
                indicatorActiveColor={'#8e44ad'}
                indicatorInActiveColor={'#ffffff'}
                indicatorActiveWidth={30}
                animation
              />
            </ScrollView>
          </Container>
        </Box>
      </Body>
    </Page>
  );
}

const styles = StyleSheet.create({
  separator: {
    height: 20,
  },
  contentStyle: {
    paddingHorizontal: 16,
  },
});
