import React, { useState, useEffect } from "react";
import { ActivityIndicator, Platform, View, Dimensions } from "react-native";
 

export default function Desktop({ children }) {
  const window = Dimensions.get("window");
  const screen = Dimensions.get("screen");
   
  const [widthStyle, setWidth] = useState({});

  const onChange = ({ window, screen }) => {
    const maxWidth = screen.height * 0.6;
    if (Platform.OS === "web") {
      if (window.width > maxWidth) {
        setWidth({
          width: maxWidth,
        });
      } else {
        setWidth({
          width: window.width,
        });
      }
    }
  };

  useEffect(() => {
    Dimensions.addEventListener("change", onChange);
    onChange({ window, screen });
    return () => {
      Dimensions.removeEventListener("change", onChange);
    };
  }, []);

  return (
    <View 
      style={[
        {
          height: "100%",
          width: "100%",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#212108",
        },
      ]}
    >
      <View
        style={[
          {
            height: "100%", 
          },
          widthStyle,
        ]}
      >
        {children}
      </View>
    </View>
  );
}
