/* eslint-disable react/react-in-jsx-scope */
import { View, StyleSheet, Dimensions } from "react-native";
import { useTemplate } from "../../context/templateContext";

export default function Page({ children, style }) {
  const colors = useTemplate().colors;
  return (
    <View
      style={[styles.page, { backgroundColor: colors.backgroundPage }, style]}
    >
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  page: {
    flex: 1,
    overflow: "hidden",
    maxHeight: Math.max(
      Dimensions.get("window").height,
      Dimensions.get("window").width
    ),
  },
});
