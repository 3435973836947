import * as React from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';


export default function Body({ children, scrollable }) {
  return (
    scrollable ?
      (
        <View style={[styles.body_View]}>
          {children}
        </View>
      ) : (
        <ScrollView style={[styles.body_Scroll]}>
          {children}
        </ScrollView>
      )
  );
}

const styles = StyleSheet.create({
  body_View: {
    paddingRight: 10,
    paddingLeft: 10,
    flex: 1
  },
  body_Scroll: {
    paddingRight: 10,
    paddingLeft: 10,
    paddingBottom: 10,
    flex: 1
  }
});
