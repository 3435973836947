import * as React from "react";
import { useState, useEffect } from "react";
import { View, StyleSheet, Text } from "react-native";
import { Modal, Column, CheckBox, Button, Br } from "../../components/baseUI";
import { WordTypesArray, getWordTypesArray } from "./staticValuesCart";
import { useTemplate } from "../../context/templateContext";

export default function TypesModal({ value = "[]", visible, onRequestClose }) {
  const [wordTypes, setWordTypes] = useState([...WordTypesArray]);
  const colors = useTemplate().colors;

  useEffect(() => {
    setWordTypes(getWordTypesArray(value));
  }, [value]);

  const onRequestCloseModal = (closeType) => {
    var types = wordTypes
      .filter((m) => m.value)
      .map((m) => m.sortKey)
      .toString(); 
    onRequestClose(closeType, { type: types });
  };

  return (
    <Modal
      visible={visible}
      backgroundColor={colors.white}
      onRequestClose={() => {
        onRequestCloseModal("Discord");
      }}
    >
      <View style={{backgroundColor:colors.white}}>
        <Column style={{ width: 250 }}>
          {wordTypes.map((item, index) => (
            <CheckBox
              key={index}
              index={index}
              color={colors.textGray}
              value={item.value == true}
              style={styles.typesRadioBox}
              title={item.name || "Empty"}
              onPress={({ index, item }) => {
                const wordTypesOld = [...wordTypes];
                wordTypesOld[index].value = !wordTypesOld[index].value;
                setWordTypes(wordTypesOld);
              }}
            />
          ))}
        </Column>

        <Br  color={colors.br}/>

        <Column style={{ flexDirection: "row" }}>
          <View style={{ flex: 1, height: 50 }}>
            <Button
              title={"Discord"}
              color={"#F178B6"}
              textColor={colors.textWhite}
              style={styles.buttons}
              onPress={() => {
                onRequestCloseModal("Discord");
              }}
            />
          </View>

          <View style={{ flex: 1, height: 50 }}>
            <Button
              title={"Select"}
              color={"#96E66E"}
              textColor={colors.textWhite}
              style={styles.buttons}
              onPress={() => {
                onRequestCloseModal("Save");
              }}
            />
          </View>
        </Column>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  typesRadioBox: {
    paddingTop: 5,
  },
  buttons: {
    height: 50,
  },
});
