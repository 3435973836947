import React, { useRef, useEffect } from "react";
import {
  Animated,
  View,
  ScrollView,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
  Image,
  Text,
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { Button, Label, LabelIcon } from "../../components/baseUI";

import { mainMenuToggle, closeMenu } from "../../redux/todos/mainMenuSlice";
import * as RootNavigation from "./Navigator";
import { useAuth } from "../../context/AuthContext";
import { useTemplate } from "../../context/templateContext";

const menuWidth = 230;

const MenuButton = ({ icon, title, screen }) => {
  const dispatch = useDispatch();
  const colors = useTemplate().colors;

  return (
    <Button
      title={
        <Label style={{ color: colors.menuTextWhite }}>
          <LabelIcon style={{ minWidth: 40 }}>{icon} </LabelIcon>
          {title}
        </Label>
      }
      color={"transparent"}
      styleText={styles.menuText}
      style={styles.menuButton}
      onPress={() => {
        RootNavigation.navigation.navigate({
          name: screen,
          params: { userName: "LoginScreen" },
        });
        dispatch(closeMenu());
      }}
    />
  );
};

function UserProfilePicture({ auth }) {
  const colors = useTemplate().colors;

  return (
    <View style={{ paddingBottom: 50 }}>
      {auth.user ? (
        <View>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              paddingTop: 30,
            }}
          >
            <Image
              style={{
                width: 90,
                height: 90,
                borderRadius: 90,
                backgroundColor: "#8b8c9d",
              }}
              source={{ uri: auth.user.photo }}
            />

            <Text
              style={[
                [
                  { color: colors.menuTextWhite },
                  { marginTop: 10, fontWeight: "bold" },
                ],
              ]}
            >
              {auth.user.displayName}
            </Text>

            <Text
              style={[[{ color: colors.menuTextWhite }, { marginTop: 2 }]]}
            >
              {auth.user.email}
            </Text>
          </View>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
}

const Menu = () => {
  const auth = useAuth();

  const colors = useTemplate().colors;

  return (
    <ScrollView>
      <View style={{ paddingTop: 30, minWidth: menuWidth }}>
        <UserProfilePicture auth={auth} />
        {/* <MenuButton icon={""} title={"Home"} screen={"HomeScreen"} /> */}

        {auth.user ? (
          <>
            <MenuButton
              icon={""}
              title={"Leitner Box"}
              screen={"LeitnerBoxScreen"}
              color={colors.menuWhite}
            />
            <MenuButton
              icon={""}
              title={"Search"}
              screen={"SearchCartScreen"}
              color={colors.menuWhite}
            />

            <MenuButton icon={""} title={"Profile"} screen={"LoginScreen"} />
          </>
        ) : (
          <>
            <MenuButton icon={""} title={"Login"} screen={"LoginScreen"} />
          </>
        )}
        {/* <MenuButton icon={""} title={"Home2"} screen={"FlatListSliderScreen"} />   */}
      </View>
    </ScrollView>
  );
};

const MainMenu = ({ children }) => {
  const dispatch = useDispatch();
  const colors = useTemplate().colors;
  //const mainWidth = 220;

  const shouldTranslateAnim = true;
  const menuToggle = useSelector(mainMenuToggle);

  const menuAnim = useRef(new Animated.Value(0)).current;
  const screenScaleAnim = useRef(new Animated.Value(1)).current;
  const screenTranslateAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (!menuToggle) {
      if (shouldTranslateAnim) {
        Animated.parallel([
          Animated.timing(menuAnim, {
            toValue: 0,
            duration: 300,
          }),
          Animated.timing(screenScaleAnim, {
            toValue: 1,
            duration: 300,
          }),
          Animated.timing(screenTranslateAnim, {
            toValue: 0,
            duration: 300,
          }),
        ]).start(() => {});
      } else {
        Animated.parallel([
          Animated.timing(menuAnim, {
            toValue: 0,
            duration: 300,
          }),
        ]).start(() => {});
      }
    } else {
      if (shouldTranslateAnim) {
        Animated.parallel([
          Animated.timing(menuAnim, {
            toValue: menuWidth,
            duration: 300,
          }),
          Animated.timing(screenScaleAnim, {
            toValue: 0.8,
            duration: 300,
          }),
          Animated.timing(screenTranslateAnim, {
            toValue: -43,
            duration: 300,
          }),
        ]).start(() => {});
      } else {
        Animated.parallel([
          Animated.timing(menuAnim, {
            toValue: menuWidth,
            duration: 300,
          }),
        ]).start(() => {});
      }
    }
  }, [menuToggle]);

  return (
    <SafeAreaView
      style={[
        styles.mainScreen,
        { backgroundColor: colors.backgroundMainScreen },
      ]}
    >
      <Animated.View
        style={[
          styles.menu,
          {
            width: menuAnim,
            backgroundColor: colors.mainColor,
          },
        ]}
      >
        <Menu />
      </Animated.View>

      <Animated.View
        style={[
          styles.mainPage,
          {
            transform: [
              { scale: screenScaleAnim },
              { translateX: screenTranslateAnim },
            ],
          },
        ]}
      >
        {children}
        {menuToggle ? (
          <TouchableOpacity
            style={styles.overMainPage}
            onPress={() => {
              dispatch(closeMenu());
            }}
          />
        ) : (
          <></>
        )}
      </Animated.View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  mainScreen: {
    flex: 1,
    height: "100%",
    flexDirection: "row",
    overflow: "hidden",
  },
  menuButton: {
    justifyContent: "flex-start",
    height: 50,
    paddingLeft: 30,
  },
  menuText: {
    textAlign: "left",
  },
  menu: {
    height: "100%",
    overflow: "hidden",
  },
  mainPage: {
    height: "100%",
    width: "100%",
    backgroundColor: "#990",
  },
  overMainPage: {
    position: "absolute",
    backgroundColor: "#FFFFFF40",
    height: "100%",
    width: "100%",
  },
});

export default MainMenu;
