import { memo, useRef, useLayoutEffect } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Linking,
} from "react-native";
import { FlatList, ActivityIndicator } from "react-native";
import CartItem from "./cartItem";
import { useTemplate } from "../../context/templateContext";

const PageSearchList = ({
  data,
  fetchNextPage = () => {},
  isFetching = false,
}) => {
  console.log("PageSearchList");

  const colors = useTemplate().colors;

  let flatListRef = useRef < FlatList > null;

  const ItemSeparatorView = () => {
    return (
      <View
        style={{
          height: 0.5,
          width: "100%",
          backgroundColor: "#C8C8C8",
        }}
      />
    );
  };

  const renderFooter = () => {
    return (
      <View style={styles.footer}>
        {isFetching ? (
          <ActivityIndicator color="black" style={{ margin: 15 }} />
        ) : null}
      </View>
    );
  };

  return (
    <FlatList
      ref={(ref) => (flatListRef = ref)}
      style={styles.list}
      data={data.items}
      renderItem={({ item }) => (
        <CartItem item={item} query={data.query} colors={colors} />
      )}
      keyExtractor={(item) => `child-key-${item.id}`}
      ItemSeparatorComponent={ItemSeparatorView}
      ListFooterComponent={renderFooter}
      onEndReached={() => {
        if (data.items.length) {
          console.log("next");
          fetchNextPage();
        }
      }}
      onEndReachedThreshold={0.1}
    />
  );
};

const styles = StyleSheet.create({
  footer: {
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  list: {
    //height: '100%'
    // maxHeight: 400
  },
});

export default memo(PageSearchList);
