import * as React from 'react';
import { View, StyleSheet } from 'react-native';


export default function Column({ children, paddingTop = 8, style }) {
  return (
    <View style={[styles.column, { paddingTop: paddingTop }, style]}>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  column: {
    flexDirection: 'column'
  },
});
