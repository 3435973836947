import React, { useState, useMemo, useRef } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Linking,
} from "react-native";
import { useDispatch } from "react-redux";
import { useQueryClient, useMutation } from "react-query";
import {
  Box,
  Container,
  Row,
  Column,
  Label,
  ButtonIcon,
  Button,
  Dialog,
  Br,
} from "../../components/baseUI";

import Toast from "../../components/ui/Toast";
import Loading from "../../components/ui/Loading";
import ErrorButton from "../../components/ui/ErrorButton";

import { upsertCart, cartLevel } from "../../services/cart";

import AddEditCartModal from "../addEditCart/addEditCartModal";

import WordFamilyItem from "../addEditCart/wordFamilyItem";
import { getWordTypeName } from "../addEditCart/staticValuesCart";
import { speak } from "../../utility/speech";

import {
  decrementCountOfCart,
  incrementCountOfCart,
  decrementCountOfReadyCart,
  incrementCountOfReadyCart,
  setCountOfCart,
} from "../../redux/todos/leitnerInfoBoxSlice";
import * as RootNavigation from "../../components/ui/Navigator";
//dimport Clipboard from '@react-native-clipboard/clipboard';
import * as Clipboard from "expo-clipboard";

import { useTemplate } from "../../context/templateContext";
import ShowStep from "../../components/ui/ShowStep";

export default function cartDetailsItem({ formData, query, changeForm }) {
  const dispatch = useDispatch();
  const colors = useTemplate().colors;

  const queryClient = useQueryClient();

  const [form, setForm] = useState(null);
  const [backupLevel, setBackupLevel] = useState(null);

  const [loadingState, setLoading] = useState(false);
  const [toastState, setToast] = useState({
    visible: false,
    message: "",
    time: 4000,
  });

  const [errorLoadState, setErrorLoadState] = useState({
    visible: false,
    message: "",
  });
  const [modalDialogDelete, setModalDialogDelete] = useState({
    visible: false,
  });
  const [modalFamilyWord, setModalFamilyWord] = useState({
    visible: false,
    value: {},
  });
  const [showAnswer, setShowAnswer] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);

  const bottomNavRef = useRef();
  const [tooltip, setTooltip] = useState({
    visible: false,
  });

  useMemo(() => {
    if (formData) {
      setForm(formData);
      if (backupLevel == null) setBackupLevel(formData.level);
    }
  }, [formData]);

  const updateQueryClient = ({ item }) => {
    if (query) {
      queryClient.setQueryData(query, (old) => {
        let result = { ...old };
        result.pages?.forEach((pageItem, pageIndex) => {
          let rowIndex = pageItem.result.data.findIndex(
            (m) => m.id === item.id
          );
          if (rowIndex != -1) {
            let newItem = {
              ...result.pages[pageIndex].result.data[rowIndex],
              ...item,
            };
            result.pages[pageIndex].result.data[rowIndex] = newItem;
          }
        });
        return result;
      });
    } else {
      // eslint-disable-next-line no-debugger
      debugger;
    }
  };

  const { mutate: mutateEdit, isLoading: isLoadingEdit } = useMutation(
    upsertCart,
    {
      onSuccess: async (result) => {
        let nowFormData = { ...form, ...result };
        updateQueryClient({ item: nowFormData });
        setLoading(false);

        if (result.isDelete == true) {
          setModalDialogDelete({ ...modalDialogDelete, visible: false });
          setToast({
            message: "🤓 This cart has been deleted successfully.",
            time: 2000,
          });
          if (changeForm) changeForm({ item: nowLoadForm });
        }
      },
      onError: (err) => {
        setToast({ message: "🤨 " + err.message, time: 2000 });
        setLoading(false);
      },
    }
  );

  const { mutate: mutatecartLevel, isLoading: isLoadingcartLevel } =
    useMutation(cartLevel, {
      onSuccess: async (result) => {
        const isAnswered = result.level != backupLevel;

        let nowFormData = {
          ...form,
          ...result,
          isAnswered,
        };

        updateQueryClient({ item: nowFormData });
        setLoading(false);
        setIsAnswered(isAnswered);

        if (isAnswered) {
          dispatch(decrementCountOfReadyCart());
        } else {
          dispatch(incrementCountOfReadyCart());
        }

        if (result.isDelete == true) {
          setModalDialogDelete({ ...modalDialogDelete, visible: false });
          setToast({
            message: "🤓 This cart has been deleted successfully.",
            time: 2000,
          });
          if (changeForm) changeForm({ item: nowLoadForm });
        }
      },
      onError: (err) => {
        setToast({ message: "🤨 " + err.message, time: 2000 });
        setLoading(false);
      },
    });

  const btnSaveNext = () => {
    setLoading(true);
    mutatecartLevel({
      cart: {
        id: form.id,
        nextStep: formData.level + 1,
      },
    });
  };

  const btnSaveBack = () => {
    setLoading(true);
    mutatecartLevel({
      cart: {
        id: form.id,
        backupLevel: backupLevel,
      },
    });
  };

  const btnSaveImLearning = () => {
    setLoading(true);
    mutatecartLevel({
      cart: {
        id: form.id,
        nextStep: 0,
      },
    });
  };

  if (!form) {
    return (
      <>
        <Text>{formData}</Text>
      </>
    );
  }

  const copyMenu = async () => {
    var text = "";
    if (form.word) text += "Word: \n  " + form.word + "\n\n";
    if (form.pronunciation)
      text += "Pronunciation: \n  " + form.pronunciation + "\n\n";
    if (form.description)
      text += "Description: \n  " + form.description + "\n\n";
    if (form.example) text += "Example: \n  " + form.example + "\n\n";
    if (form.tags)
      text +=
        "\n" + getWordTypeName(form.type, " ", "#") + " " + form.tags + "\n\n";

    await Clipboard.setStringAsync(text.trim());
    setToast({
      message: "🤓 Copied.",
      time: 2000,
    });
  };

  const stylesT = StyleSheet.create({
    toolTip: {
      position: "absolute",
      left: 10,
      top: 20,
      minWidth: 180,
      padding: 5,
      borderRadius: 6,
      shadowOffset: {
        width: 1,
        height: 1,
      },
      shadowOpacity: 0.5,
      shadowRadius: 10,
      zIndex: 1000,
    },
    menuButton: {
      minHeight: 20,
      width: "100%",
    },
    menuText: {
      margin: 12,
    },
    menuIcon: {
      fontFamily: "fontello",
    },
  });

  const Tooltip = ({ visible }) => {
    const colors = useTemplate().colors;
    return visible ? (
      <View
        style={[stylesT.toolTip, { backgroundColor: colors.tooltipBackground }]}
      >
        <TouchableOpacity
          style={stylesT.menuButton}
          onPress={async () => {
            await copyMenu();
            setTooltip({ visible: false });
          }}
        >
          <Text style={[stylesT.menuText, { color: colors.textGray }]}>
            <Text style={stylesT.menuIcon}> </Text> Copy
          </Text>
        </TouchableOpacity>
        <Br color={colors.br} />
        <TouchableOpacity
          style={stylesT.menuButton}
          onPress={() => {
            Linking.openURL(
              "https://dictionary.cambridge.org/dictionary/english/" +
                form.word.trim()
            );
            setTooltip({ visible: false });
          }}
        >
          <Text style={[stylesT.menuText, { color: colors.textGray }]}>
            <Text style={stylesT.menuIcon}> </Text> Dictionary
          </Text>
        </TouchableOpacity>
        <Br color={colors.br} />
        <TouchableOpacity
          style={stylesT.menuButton}
          onPress={() => {
            setTooltip({ visible: false });
          }}
        >
          <Text style={[stylesT.menuText, { color: colors.textGray }]}>
            <Text style={stylesT.menuIcon}> </Text> Cancel
          </Text>
        </TouchableOpacity>
      </View>
    ) : (
      <></>
    );
  };

  return (
    <>
      <Box>
        <Container>
          <Column>
            <Row justifyContent={"space-between"}>
              <Row>
                <View ref={bottomNavRef}>
                  <ButtonIcon
                    title={""} //menu
                    color={colors.editBtn}
                    fontSize={22}
                    onPress={() => {
                      setTooltip({ visible: true });
                    }}
                  />
                </View>
                <ShowStep step={form.level} color={colors.editBtn} />
              </Row>

              <Row>
                <ButtonIcon
                  title={""} //edit
                  color={colors.editBtn}
                  fontSize={22}
                  onPress={() => {
                    setModalFamilyWord({
                      value: { pageId: form.id, pageParentId: 0 },
                      visible: true,
                    });
                  }}
                />
              </Row>
            </Row>

            <Label>{form.word}</Label>
          </Column>

          <Column>
            <Label>
              {getWordTypeName(form.type)} {form.tags}
            </Label>
          </Column>

          <Column>
            <Row justifyContent={"space-around"}>
              <ButtonIcon
                title={form.hasFavourite == 0 ? "" : ""} //hurt
                color={
                  [colors.hurt0, colors.hurt1, colors.hurt2][form.hasFavourite]
                }
                fontSize={32}
                onPress={() => {
                  let newHasFavourite =
                    form.hasFavourite < 2 ? form.hasFavourite + 1 : 0;
                  setForm({ ...form, hasFavourite: newHasFavourite });
                  setLoading(true);
                  mutateEdit({
                    cart: {
                      id: form.id,
                      hasFavourite: newHasFavourite,
                    },
                  });
                }}
              />

              <ButtonIcon
                title={form.hasStar == 0 ? "" : ""} //star
                color={[colors.star0, colors.star1, colors.star2][form.hasStar]}
                fontSize={32}
                onPress={() => {
                  let newHasStar = form.hasStar < 2 ? form.hasStar + 1 : 0;
                  setForm({ ...form, hasStar: newHasStar });
                  setLoading(true);
                  mutateEdit({
                    cart: {
                      id: form.id,
                      hasStar: newHasStar,
                    },
                  });
                }}
              />

              <ButtonIcon
                title={""} //delete
                color={colors.deleteBtn}
                fontSize={33}
                onPress={() => {
                  setModalDialogDelete({
                    ...modalDialogDelete,
                    visible: true,
                  });
                }}
              />

              <ButtonIcon
                title={""} //speak
                color={colors.speakBtn}
                fontSize={32}
                onPress={() => {
                  speak(form.word);
                }}
              />
            </Row>
          </Column>

          <Br style={{ paddingTop: 20 }} color={colors.br} />
        </Container>

        {!showAnswer ? (
          <TouchableOpacity
            onPress={() => {
              setShowAnswer(true);
            }}
            style={{
              flex: 1,
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                position: "absolute",
                padding: 10,
                width: "100%",
                height: "100%",
              }}
            >
              <View
                style={{
                  backgroundColor: colors.hiddenBtn,
                  height: 15,
                  width: "27%",
                  borderRadius: 6,
                  marginTop: 4,
                }}
              ></View>
              <View
                style={{
                  backgroundColor: colors.hiddenBtn,
                  height: 15,
                  width: "20%",
                  borderRadius: 6,
                  marginTop: 7,
                }}
              ></View>
            </View>
            <Text
              style={{
                width: "100%",
                color: colors.threeBtn,
                fontSize: 60,
                textAlign: "center",
              }}
            >
              ...
            </Text>
          </TouchableOpacity>
        ) : (
          <Container paddingTop={0}>
            <Column paddingTop={0}>
              {form.pronunciation ? <Label>{form.pronunciation}</Label> : <></>}
              <Label>Description</Label>
              <Label>{form.description}</Label>

              <Label>Example</Label>
              <Label>{form.example}</Label>
            </Column>

            {form.relatedCards && form.relatedCards.length ? (
              <Column>
                <Row justifyContent={"space-between"}>
                  <Label>Word Family</Label>
                </Row>
                <View>
                  {form.relatedCards &&
                    form.relatedCards.map((item, i) => {
                      return (
                        <WordFamilyItem item={item} id={item.id} key={i} />
                      );
                    })}
                </View>
              </Column>
            ) : (
              <></>
            )}
          </Container>
        )}

        {isAnswered == false ? (
          <Row>
            <View style={{ flex: 1, height: 70 }}>
              <Button
                title={"  I'm learning"}
                color={colors.redBtn}
                textColor={colors.textWhite}
                onPress={btnSaveImLearning}
              />
            </View>

            <View style={{ flex: 1, height: 70 }}>
              <Button
                title={"  I know"}
                color={colors.greenBtn}
                textColor={colors.textWhite}
                onPress={btnSaveNext}
              />
            </View>
          </Row>
        ) : (
          <Row>
            <View style={{ flex: 1, height: 70 }}>
              <Button
                title={"Back "}
                color={colors.textGray}
                textColor={colors.textWhite}
                onPress={btnSaveBack}
              />
            </View>
          </Row>
        )}
      </Box>

      {modalFamilyWord.visible ? (
        <AddEditCartModal
          value={modalFamilyWord.value}
          visible={modalFamilyWord.visible}
          onRequestClose={(closeType, value) => {
            setModalFamilyWord({ value: { pageId: "" }, visible: false });
          }}
          changeForm={({ item, isAdding }) => {
            setModalFamilyWord({ ...modalFamilyWord, visible: false });
            setForm({ ...form, ...item });
          }}
          closeOnPress={() => {
            setModalFamilyWord({ value: { pageId: "" }, visible: false });
          }}
        />
      ) : (
        <></>
      )}

      <Dialog
        visible={modalDialogDelete.visible}
        text="Are you sure to delete?"
        OkText="OK"
        CancelText="Cancel"
        onRequestClose={(result) => {
          setModalDialogDelete({ ...modalDialogDelete, visible: false });
          if (result === "OK") {
            mutateEdit({
              cart: {
                id: form.id,
                isDelete: true,
              },
            });
          }
        }}
      />

      <Loading visible={loadingState} />

      <ErrorButton
        visible={errorLoadState.visible}
        message={errorLoadState.message}
        onPress={() => {
          //queryClient.refetchQueries(["detailCart", pageId]);
          setErrorLoadState({ ...errorLoadState, visible: false });
          setLoading(true);
        }}
      />

      <Toast
        {...toastState}
        onHide={() => {
          setToast({ visible: false });
        }}
      />

      <Tooltip {...tooltip} />
    </>
  );
}
