import * as React from "react";
import { View, StyleSheet } from "react-native";
import { useTemplate } from "../../context/templateContext";

export default function Box({ children }) {
  const colors = useTemplate().colors;

  const styles = StyleSheet.create({
    box: {
      shadowOffset: {
        width: 5.25,
        height: 0.75,
      },
      shadowOpacity: 0.2825,
      shadowRadius: 9.85,
      shadowColor: colors.boxShadow,

      backgroundColor: colors.boxBackground,
      borderLeftColor: colors.boxBorderGray,

      borderLeftWidth: 4,
      // overflow: "hidden",
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
      marginTop: 10,
      marginBottom: 10, 
    },
  });

  return (
    <View style={styles.box}>
      <View>{children}</View>
    </View>
  );
}
