import React, { useLayoutEffect, useState, useEffect, useMemo } from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Linking, Dimensions } from "react-native";
import { useSelector, useDispatch } from 'react-redux';
import { useInfiniteQuery, useQueryClient } from "react-query";
import { Page, Body, Box, Container, ButtonIcon, Button, Row, Label, TextInput } from '../../components/baseUI';
import * as RootNavigation from '../../components/ui/Navigator';
import LeitnerBox from ".";

export default function LeitnerBoxScreen({ navigation }) {
    // useLayoutEffect(() => {
    //     if (navigation) {
    //         navigation.setOptions({
    //             headerRight: () => (
    //                 <View
    //                     style={{ flexDirection: "row", paddingRight: 10, paddingTop: 10 }}
    //                 >
    //                     <ButtonIcon
    //                         title={""}
    //                         color={"#fff"}
    //                         fontSize={32}
    //                         style={{ paddingLeft: 10 }}
    //                         onPress={() => {
    //                             RootNavigation.navigation.navigate({ name: 'AddEditCartScreen', params: {} });
    //                         }}
    //                     />
    //                 </View>
    //             ),
    //         });
    //     }
    // }, [navigation]);



    return (
        <LeitnerBox navigation={navigation}/>
    );
}

