import * as React from "react";
import { View, StyleSheet } from "react-native";
import { ModalScreen } from "../../components/baseUI";
import CartDetails from "./cartDetails";
import { useDispatch } from 'react-redux';
import { hideCartDetail } from '../../redux/todos/cartDetailSlice';


const cartDetailsModal = React.memo(({ value = {}, visible, onRequestClose }) => {
  const dispatch = useDispatch();
  return (
    <ModalScreen
      visible={visible}
      onRequestClose={() => {
        onRequestClose("Discord");
      }}
    >
      <View style={styles.modalBody}>
        <CartDetails {...value} isModal={true} changeForm={() => {
          dispatch(hideCartDetail());
        }} />
      </View>
    </ModalScreen>
  );
});


const styles = StyleSheet.create({
  modalBody: {
    padding: "0%",
    width: '100%'
  },
  itemWordFamily: {
    padding: 5,
    marginVertical: 5,
    marginHorizontal: 5,
    justifyContent: "space-between",
  },

  itemWord: {
    fontSize: 18,
    justifyContent: "space-around"
  },

  itemWordFamilyBtn: {
    paddingTop: 3,
    fontSize: 16,
  },
});

export default cartDetailsModal;