import * as React from "react";
import { Image, View, Text } from "react-native";
import {
  NavigationContainer,
  createNavigationContainerRef,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useDispatch } from "react-redux";

import { ButtonIcon } from "../baseUI";
import { toggleMenu } from "../../redux/todos/mainMenuSlice";

import HomeScreen from "../../screens/home/homeScreen";
import LoginScreen from "../../screens/login/loginScreen";
import SearchCartScreen from "../../screens/searchCart/searchCartScreen";
import LeitnerBoxScreen from "../../screens/leitnerBox/leitnerBoxScreen";
import AddEditCartScreen from "../../screens/addEditCart/addEditCartScreen";
import FlatListSliderScreen from "../../screens/FlatListSlider/flatListSliderScreen";

import { useAuth } from "../../context/AuthContext";
import { useTemplate } from "../../context/templateContext";

export const navigation = createNavigationContainerRef();

const Stack = createNativeStackNavigator();

const options = ({ navigation, title }) => {
  const template1 = useTemplate();

  return {
    title: title,
    headerTitle: (props) => <></>,
    headerShadowVisible: true,
    headerStyle: {
      backgroundColor: template1.colors.mainColor,
      height: 60,
      shadowOffset: {
        width: 5.25,
        height: 0.75,
      },
      shadowOpacity: 1, // 0.2825,
      shadowRadius: 3,
      shadowColor: template1.colors.mainColor,
      borderBottomWidth: 0,
    },
    headerTintColor: template1.colors.menuTextWhite,
    headerTitleStyle: {
      fontWeight: "bold",
    },
    headerBackVisible: false,
    //headerShown: false,
    headerLeft: () => {
      const dispatch = useDispatch();
      return (
        <View style={{ flexDirection: "row", paddingLeft: 11, paddingTop: 10 }}>
          <ButtonIcon
            title={""}
            color={template1.colors.menuTextWhite}
            fontSize={32}
            onPress={() => dispatch(toggleMenu())}
          />

          <View style={{ width: 25 }}></View>

          <ButtonIcon
            title={template1.getTemplate() === 0 ? "" : ""}
            color={template1.colors.menuTextWhite}
            fontSize={32}
            onPress={() => {
              if (template1.getTemplate() === 0) {
                template1.setTemplate(1);
              } else {
                template1.setTemplate(0);
              }
            }}
          />
        </View>
      );
    },
  };
};

export default function Navigator() {
  const auth = useAuth();
  const initialRouteName =
    auth.user === null ? "LoginScreen" : "LeitnerBoxScreen";

  return (
    <NavigationContainer
      ref={navigation}
      documentTitle={{
        formatter: (options, route) =>
          `${options?.title ?? route?.name} - Leitner Plus`,
      }}
    >
      <Stack.Navigator initialRouteName={initialRouteName}>
        <Stack.Screen
          name="LoginScreen"
          title="Login"
          options={(navigation) => options({ navigation, title: "Login" })}
          component={LoginScreen}
        />

        <Stack.Screen
          name="HomeScreen"
          options={(navigation) => options({ navigation, title: "Home" })}
          component={HomeScreen}
        />

        <Stack.Screen
          name="AddEditCartScreen"
          options={(navigation) =>
            options({ navigation, title: "Add or edit Cart" })
          }
          component={AddEditCartScreen}
        />

        <Stack.Screen
          name="FlatListSliderScreen"
          options={(navigation) =>
            options({ navigation, title: "FlatListSliderScreen" })
          }
          component={FlatListSliderScreen}
        />

        <Stack.Screen
          name="SearchCartScreen"
          options={(navigation) => options({ navigation, title: "Search" })}
          component={SearchCartScreen}
        />

        <Stack.Screen
          name="LeitnerBoxScreen"
          options={(navigation) => options({ navigation, title: "Main Box" })}
          component={LeitnerBoxScreen}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export function navigate({ name, params }) {
  if (navigation.isReady()) {
    navigation.navigate(name, params);
  }
}
