import * as React from "react";
import { useState } from "react";
import { View, StyleSheet } from "react-native";
import { ModalScreen } from "../../components/baseUI";
import AddEditCart from ".";
 
export default function AddEditCartModal({ value = {}, visible, onRequestClose, changeForm, closeOnPress }) {
  const [word, setWord] = useState(value.word || "");
  const onRequestCloseModal = (closeType) => {
    var types = [];
    onRequestClose(closeType, { word: word, types: types });
  };

  return (
    <ModalScreen
      visible={visible}
      onRequestClose={() => {
        onRequestCloseModal("Discord");
      }}
    >
      <View style={styles.modalBody}>
        {
          visible ?
            <AddEditCart {...value} isModal={true} changeForm={changeForm} closeOnPress={closeOnPress} />
            :
            <></>
        }
      </View>
    </ModalScreen>
  );
}

const styles = StyleSheet.create({
  modalBody: {
    padding: "0%",
    width: '100%'
  },
  buttons: {
    height: 50,
  },
});
