export const WordTypesArray = [
  { sortKey: 1, code: "1", name: "Noun", otherName: ["Noun", "noun"] },
  { sortKey: 2, code: "2", name: "Verb", otherName: ["Verb", "verb"] },
  { sortKey: 3, code: "3", name: "Adjective", otherName: ["Adjective", "adjective", "adj"] },
  { sortKey: 4, code: "4", name: "Adverb", otherName: ["Adverb", "adverb", "adv"] },
  { sortKey: 5, code: "5", name: "Compound", otherName: ["Compound", "compound", "com"] },
  { sortKey: 6, code: "6", name: "Collocation", otherName: ["Collocation", "collocation", "col"] },
  { sortKey: 7, code: "7", name: "Idiom", otherName: ["Idiom", "idiom"] },
  { sortKey: 8, code: "8", name: "Phrasal", otherName: ["Phrasal", "phrasal", "phr"] },
  { sortKey: 9, code: "9", name: "Article", otherName: ["Article", "article", "art"] },
];

export const WordTypesEnum = {
  Noun: "1",
  Verb: "2",
  Adj: "3",
  Adv: "4",
  Compound: "5",
  Collocation: "6",
  Idiom: "7",
  Phrasal: "8",
  Article: "9",
};

export function getWordTypesArray(typeSelected) {
  var newWordTypesArray = [...WordTypesArray];
  if (typeof typeSelected === "number") {
    typeSelected = typeSelected + "";
  }
  if (typeSelected.length) {
    const typesSelected = typeSelected.split(",");
    for (let n = 0; n < newWordTypesArray.length; n++) {
      const found = typesSelected.findIndex(
        (m) => m == newWordTypesArray[n].code
      );
      if (found != -1) {
        newWordTypesArray[n].value = true;
      } else {
        newWordTypesArray[n].value = false;
      }
    }
  }
  return newWordTypesArray;
}

export function getWordTypeName(typeSelected, joinCode = " ", prefix = "") {
  const wordTypes = getWordTypesArray(typeSelected)
    .filter((m) => m.value)
    .map((m) => {
      return prefix + m.name;
    });
  return wordTypes.join(joinCode);
}


export function clipboardConvertToObject(clipboardContent) {
  //debugger;
  let lines = clipboardContent.split("\n").map(m => ({ text: m.replace(/\r/g, ""), key: "", mainKey: "", hasKey: false }));

  for (let index = 0; index < lines.length; index++) {
    const key = lines[index].text.includesArray(["Word:", "word:", "Word :", "word :"]);
    if (key !== null) {
      lines[index].key = key;
      lines[index].hasKey = true;
      lines[index].mainKey = "word";
      lines[index].text = lines[index].text.replace(key, "").trim();
      break;
    }
  }

  for (let index = 0; index < lines.length; index++) {
    const key = lines[index].text.includesArray(["Pronunciation:", "pronunciation:", "Pronunciation :", "pronunciation :"]);
    if (key !== null) {
      lines[index].key = key;
      lines[index].hasKey = true;
      lines[index].mainKey = "pronunciation";
      lines[index].text = lines[index].text.replace(key, "").trim();
      break;
    }
  }

  for (let index = 0; index < lines.length; index++) {
    const key = lines[index].text.includesArray(["Description:", "description:", "Description :", "description :"]);
    if (key !== null) {
      lines[index].key = key;
      lines[index].hasKey = true;
      lines[index].mainKey = "description";
      lines[index].text = lines[index].text.replace(key, "").trim();
      break;
    }
  }

  for (let index = 0; index < lines.length; index++) {
    const key = lines[index].text.includesArray(["Example:", "example:", "Description :", "example :"]);
    if (key !== null) {
      lines[index].key = key;
      lines[index].hasKey = true;
      lines[index].mainKey = "example";
      lines[index].text = lines[index].text.replace(key, "").trim();
      break;
    }
  }


  const hasTagsEndLine = lines[lines.length - 1].text.includes("#");
  if (hasTagsEndLine) {
    lines[lines.length - 1].key = "tags";
    lines[lines.length - 1].hasKey = true;
    lines[lines.length - 1].mainKey = "allTags";
    lines[lines.length - 1].text = lines[lines.length - 1].text.trim();
  }

  let result = { word: "", description: "", example: "", pronunciation: "", tags: "", type: "", allTags: "" };
  let key = "";
  for (let index = 0; index < lines.length; index++) {
    if (lines[index].hasKey) {
      if (key) {
        result[key] = result[key].trim();
      }
      key = lines[index].mainKey;
    }
    if (key) {
      result[key] += lines[index].text + "\n";
    }
  }

  result[key] = result[key].trim();
  const allTags = result.allTags.split(" ").map(m => ({ text: m, isType: false, code: "" }));

  for (let i = 0; i < allTags.length; i++) {
    const tag = allTags[i].text;
    for (let index = 0; index < WordTypesArray.length; index++) {
      const isType = tag.replace("#", "").includesArray(WordTypesArray[index].otherName);
      if (isType !== null) {
        allTags[i].isType = true;
        allTags[i].code = WordTypesArray[index].code;
        break;
      }
    }
  }

  result.type = allTags.filter(m => m.isType).map(m => m.code).join();
  result.tags = allTags.filter(m => !m.isType).map(m => m.text).join(" ");
  delete result.allTags;

  return result;
};
