import React, { useRef, useEffect, useState } from 'react';
import { Text, View, StyleSheet, Animated, Dimensions } from "react-native";
import { useSelector, useDispatch } from 'react-redux';


export default function Toast({ visible, message, time = 2000, onHide = () => { } }) {
  const showAnimated = useRef(new Animated.Value(0)).current;
  const [visibleInner, setVisibleInner] = useState(visible);
  const [displaying, setDisplaying] = useState(false);

  useEffect(() => {
    if (visible) {
      setVisibleInner(true);
      setTimeout(() => {
        setVisibleInner(false);
        onHide();
      }, time);
    }
  }, [visible])

  useEffect(() => {
    if (visibleInner) {
      setDisplaying(true);
      Animated.parallel([
        Animated.timing(showAnimated, {
          toValue: 1,
          duration: 500,
        }),
      ]).start(() => { });

    } else {
      Animated.parallel([
        Animated.timing(showAnimated, {
          toValue: 0,
          duration: 500,
        }),
      ]).start(() => {
        setDisplaying(false);
      });
    }
  }, [visibleInner]);


  return (
    <Animated.View style={[styles.centeredView, { opacity: showAnimated, display: !displaying ? 'none' : 'flex' }]}>
      <View style={[styles.modalView, { minHeight: 40 }]}>
        <Text style={[styles.text]}>
          {message}
        </Text>
      </View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "flex-start",
    top: (Dimensions.get('window').height - 280),
    alignItems: "center",
    minHeight: '10%',
    width: '100%',
    position: 'absolute',
  },
  modalView: {
    "shadowOffset": {
      "width": 1,
      "height": 1
    },
    "shadowOpacity": 0.5,
    "shadowRadius": 17.084375,
    margin: 16,
    backgroundColor: "white",
    borderRadius: 8,
    alignItems: "flex-start",
    shadowColor: "#000",
    elevation: 5,
    overflow: "hidden",
    width: "90%",
    padding: 5
  },
  text: {
    padding: 8,
    fontSize: 20
  },
});
