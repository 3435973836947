import React from "react";
import "./src/utility/extensions";
import LoadDepends from "./src/components/ui/LoadDepends";
import Desktop from "./src/components/ui/Desktop";
import MainMenu from "./src/components/ui/MainMenu";
import Navigator from "./src/components/ui/Navigator";
import AuthProvider from "./src/context/AuthContext";
import TemplateContext from "./src/context/templateContext";
//import SQLiteTest from "./SQLiteTest";
console.clear();

function App() {
  // return (<SQLiteTest/>);
  return (
    <AuthProvider>
      <LoadDepends>
        <TemplateContext>
          <Desktop>
            <MainMenu>
              <Navigator />
            </MainMenu>
          </Desktop>
        </TemplateContext>
      </LoadDepends>
    </AuthProvider>
  );
}

export default App;
