import * as React from "react";
import {
  Text,
  View,
  StyleSheet,
  ScrollView,
  Modal as ModalReactNative,
  TouchableOpacity,
} from "react-native";
import Desktop from "../ui/Desktop";

export default function Modal({
  children,
  visible,
  onRequestClose,
  scrollable,
  backgroundColor = "#000",
}) {
  return scrollable ? (
    <View style={{ backgroundColor: backgroundColor }}>
      {innerModal({ children, visible, onRequestClose })}
    </View>
  ) : (
    <ScrollView style={{ backgroundColor: backgroundColor }}>
      {innerModal({ children, visible, onRequestClose, backgroundColor })}
    </ScrollView>
  );
}

function innerModal({ children, visible, onRequestClose, backgroundColor }) {
  return (
    <View style={{ backgroundColor: backgroundColor }}>
      <ModalReactNative
        animationType="slide"
        transparent={true}
        visible={visible}
        onRequestClose={() => {
          onRequestClose();
        }}
      >
        <View style={[styles.centeredView]}>
          <View
            style={[styles.modalView, { backgroundColor: backgroundColor }]}
          >
            <TouchableOpacity
              style={[styles.buttonClose]}
              onPress={() => {
                onRequestClose();
              }}
            >
              <Text style={styles.titleButtonClose}>  </Text>
            </TouchableOpacity>
            <View>{children}</View>
          </View>
        </View>
      </ModalReactNative>
    </View>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#afafaf5a",
  },
  modalView: {
    margin: 16,
    borderRadius: 20,
    padding: 20,
    alignItems: "flex-start",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  buttonClose: {
    padding: 9,
    backgroundColor: "#2196F3",
    marginTop: -40,
    borderRadius: 8,
  },
  titleButtonClose: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "fontello",
    fontSize: 18,
  },
});
