import * as React from 'react';
import { View, StyleSheet } from 'react-native';


export default function Footer({ children, style }) {
  return (
    <View style={[styles.footer, style]}>
      {children}
    </View>
  );
}


const styles = StyleSheet.create({
  footer: {
    minHeight: 30,
    justifyContent: "space-between",
    flexDirection: 'row'
  },
});
