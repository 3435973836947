import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../todos/counterSlice';
import mainMenuSlice from '../todos/mainMenuSlice'; 
import cartDetailSlice from '../todos/cartDetailSlice';
import cartAddEditSlice from '../todos/cartAddEditSlice';
import searchBoxLeitnerBoxSlice from '../todos/searchBoxLeitnerBoxSlice';
import searchBoxAllCartSlice from '../todos/searchBoxAllCartSlice';
import leitnerInfoBoxSlice from '../todos/leitnerInfoBoxSlice';


export default configureStore({
  reducer: {
    mainMenuSlice: mainMenuSlice, 
    cartDetailSlice: cartDetailSlice,
    cartAddEditSlice: cartAddEditSlice,
    leitnerInfoBoxSlice: leitnerInfoBoxSlice,
    searchBoxLeitnerBoxSlice:searchBoxLeitnerBoxSlice,
    searchBoxAllCartSlice:searchBoxAllCartSlice,
    counter: counterReducer,
  },
});
