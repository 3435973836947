import { createSlice } from '@reduxjs/toolkit';

export const cartAddEditSlice = createSlice({
    name: 'cartAddEditState',
    initialState: {
        visible: false,
        value:[]
    },
    reducers: {
        setCartAddEdit: (state, { payload }) => {
            state.visible = payload.visible;
            state.value = payload.value; 
        },
        hideCartAddEdit: (state) => {
            state.visible = false;
        }
    },
});

export const { setCartAddEdit, hideCartAddEdit } = cartAddEditSlice.actions;

export const cartAddEditState = (state) => state.cartAddEditSlice;

export default cartAddEditSlice.reducer
