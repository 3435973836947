export default {
  isDark: false,

  black: "#000000",
  white: "#ffffff",
  menuWhite: "#ffffff",

  boxShadow: "#252525",
  boxBackground: "#ffffff",
  boxBorderGray: "#c4c4c4",

  menuTextWhite: "#ffffff",
  backgroundMainScreen: "#d8d8d8",
  backgroundPage: "#F9F9F9",

  whiteSmoke: "#f5fcff",
  mainColor: "#71abd2",
  greenColor: "#51c04d",
  redColor: "#e66460",
  borderGray: "#c4c4c4",

  textGray: "#5B5969",
  textWhite: "#ffffff",
  hiddenBtn: "#dddddd",

  greenBtn: "#6cc334",
  redBtn: "#e66460",

  speakBtn: "#55a0fb",
  editBtn: "#444444",

  saveBtn: "#96E66E",
  discordBtn: "#F178B6",

  deleteBtn: "#312967",
  threeBtn: "#999999",

  hurt0: "#111111",
  hurt1: "#FF1490",
  hurt2: "#FF4500",

  star0: "#111111",
  star1: "#e2ff00",
  star2: "#ffdf00",

  tooltipBackground: "#fcfcfc",
  br: "#cccccc",

  inputBackgroundColor: "#ffffff",
  inputBorderColor: "#C4C4C4",
  inputPlaceholderColor: "#A8A3B4",
  inputColor: "#111111",
};
