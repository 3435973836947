import * as React from "react";
import { Text, View, TouchableOpacity, ActivityIndicator } from "react-native";

export default function Button({
  title,
  color = "#007bff",
  textColor = "#ffffff",
  onPress,
  style = {},
  styleText = {},
  loading = false,
  enable = true,
  accessibilityLabel,
}) {
  const enableOnPress = !loading && enable;
  return (
    <TouchableOpacity
      onPress={() => {
        if (enableOnPress) onPress();
      }}
      title={title || ""}
      color={"transparent"}
      accessibilityLabel={accessibilityLabel || ""}
    >
      <View
        style={[
          {
            backgroundColor: color,
            height: style.height || 70,
            justifyContent: "center",
            flexDirection: "column",
          },
          style,
        ]}
      >
        <Text
          style={[
            {
              fontSize: 22,
              fontFamily: "fontello",
              textAlign: "center",
              color: enableOnPress ? textColor : (textColor + "70"),
            },
            styleText,
          ]}
        >
          {title}
        </Text>
        {loading && (
          <ActivityIndicator
            style={{ position: "absolute", width: "100%" }}
            size={"large"}
          />
        )}
      </View>
    </TouchableOpacity>
  );
}
