import * as React from "react";
import { Text, View, StyleSheet, Modal } from "react-native";
import Button from "./Button";
import Br from "./Br";
import { useTemplate } from "../../context/templateContext";

export default function Dialog({
  visible = true,
  onRequestClose,
  text = "Message",
  CancelText = "Cancel",
  OkText = "OK",
}) {
  const colors = useTemplate().colors;

  return (
    <View style={[styles.body_View]}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={visible}
        onRequestClose={() => {
          if (onRequestClose) onRequestClose("Close");
        }}
      >
        <View style={styles.centeredView}>
          <View style={[styles.modalView, { backgroundColor: colors.white }]}>
            <View>
              <Text style={[styles.text, { color: colors.textGray }]}>
                {text}
              </Text>
            </View>

            <Br color={colors.br} />

            <View
              style={{ flexDirection: "row", width: "100%", paddingTop: 10 }}
            >
              {CancelText ? (
                <View style={{ flex: 1, height: 50 }}>
                  <Button
                    title={CancelText}
                    color={"#F178B6"}
                    style={styles.buttons}
                    textColor={colors.textWhite}
                    onPress={() => {
                      if (onRequestClose) onRequestClose(CancelText);
                    }}
                  />
                </View>
              ) : (
                <></>
              )}
              {OkText ? (
                <View style={{ flex: 1, height: 50 }}>
                  <Button
                    title={OkText}
                    color={"#96E66E"}
                    style={styles.buttons}
                    textColor={colors.textWhite}
                    onPress={() => {
                      if (onRequestClose) onRequestClose(OkText);
                    }}
                  />
                </View>
              ) : (
                <></>
              )}
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#afafaf5a",
  },
  modalView: {
    margin: 16,
    borderRadius: 20,
    padding: 20,
    alignItems: "flex-start",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    minWidth: 260,
  },
  buttonClose: {
    padding: 9,
    backgroundColor: "#2196F3",
    marginTop: -40,
    borderRadius: 8,
  },
  titleButtonClose: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "fontello",
    fontSize: 18,
  },
  text: {
    paddingTop: 5,
    paddingBottom: 10,
    fontSize: 18,
  },
  buttons: {
    height: 50,
  },
});
