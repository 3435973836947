import * as React from "react";
import { View, StyleSheet } from "react-native";

export default function Br({ style, color = "#cccccc" }) {
  return <View style={[styles.Br, style, { borderBottomColor: color }]} />;
}

const styles = StyleSheet.create({
  Br: {
    paddingTop: 5,
    borderBottomWidth: StyleSheet.hairlineWidth,
    width: "100%",
  },
});
