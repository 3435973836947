import { createSlice } from '@reduxjs/toolkit';

export const searchBoxLeitnerBoxSlice = createSlice({
    name: 'searchBoxLeitnerBoxSlice',
    initialState: {
        visible: false,
        value:[]
    },
    reducers: {
        setVisibleBoxLeitnerBox: (state, { payload }) => { 
            state.visible = payload; 
        }
    },
});

export const { setVisibleBoxLeitnerBox } = searchBoxLeitnerBoxSlice.actions;

export const searchBoxLeitnerBoxState = (state) => state.searchBoxLeitnerBoxSlice;

export default searchBoxLeitnerBoxSlice.reducer
