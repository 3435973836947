export default {
    isDark: false,
  
    black: "#ffffff",
    white: "#000000",
    menuWhite: "#000000",
  
    boxShadow: "#ffffff",
    boxBackground: "#333333",
    boxBorderGray: "#c4c4c4",
  
    menuTextWhite: "#000000",
    backgroundMainScreen: "#a3a3a3",
    backgroundPage: "#747474",
  
    whiteSmoke: "#f5fcff",
    mainColor: "#424242",
    greenColor: "#51c04d",
    redColor: "#e66460",
    borderGray: "#5c5c5c",
  
    textGray: "#d2d2d2",
    textWhite: "#000000",
    hiddenBtn: "#5a5a5a",
  
    greenBtn: "#6cc334",
    redBtn: "#e66460",
  
    speakBtn: "#55a0fb",
    editBtn: "#d2d2d2",
  
    saveBtn: "#96E66E",
    discordBtn: "#F178B6",
  
    deleteBtn: "#672929",
    threeBtn: "#999999",
  
    hurt0: "#c2c2c2",
    hurt1: "#FF1490",
    hurt2: "#FF4500",
  
    star0: "#c2c2c2",
    star1: "#e2ff00",
    star2: "#ffdf00",
  
    tooltipBackground: "#595959",
    br: "#cccccc",
  
  
    inputBackgroundColor: "#2b2b2b",
    inputBorderColor: "#c9c9c9",
    inputPlaceholderColor: "#5e5e5e",
    inputColor: "#fcfcfc",
  };
  