import { createSlice } from '@reduxjs/toolkit'

export const mainMenuSlice = createSlice({
    name: 'main menu',
    initialState: {
        toggle: false,
    },
    reducers: {
        openMenu: (state) => {
            state.toggle = true
        },
        closeMenu: (state) => {
            state.toggle = false
        },
        toggleMenu: (state) => {
            state.toggle = !state.toggle
        },
    },
})

export const { openMenu, closeMenu, toggleMenu } = mainMenuSlice.actions;

export const mainMenuToggle = (state) => state.mainMenuSlice.toggle

export default mainMenuSlice.reducer
