import { useEffect } from "react";
import { ActivityIndicator, LogBox, Platform } from "react-native";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider, focusManager } from "react-query";
import { useFonts } from "expo-font";
import axios from "axios";

import { useAppState } from "../../hooks/useAppState";
import { useOnlineManager } from "../../hooks/useOnlineManager";
import store from "../../redux/stores/index";
import { useAuth } from "../../context/AuthContext";
import { useState } from "react";

axios.defaults.withCredentials = true;
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

try {
  axios.defaults.baseURL = process.env.APP_MANIFEST.apiUrl;
} catch {
  axios.defaults.baseURL = "https://api.leitnerplus.com"; // process.env.NODE_ENV === 'production' ? 'https://api.leitnerplus.com' : 'http://localhost:5000';
  //alert(axios.defaults.baseURL);
}

if (process.env.NODE_ENV !== "production") {
  console.log(process.env);
  console.log("baseURL:" + axios.defaults.baseURL);
}

let customFonts = {
  fontello: require("../../../assets/fonts/fontello.ttf"),
};

function onAppStateChange(status) {
  if (Platform.OS !== "web") {
    focusManager.setFocused(status === "active");
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: Platform.OS !== "web",
    },
  },
});

export default function LoadDepends({ children }) {
  useOnlineManager();
  useAppState(onAppStateChange);
  const auth = useAuth();
  const [fontLoaded] = useFonts(customFonts);
  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    auth
      .loadUser()
      .then((user) => { 
        setUserLoaded(true);
        console.log("LoadDepends > loadUser : ", JSON.stringify(user));
        auth.updateUser();
      })
      .catch(() => { 
        setUserLoaded(true);
      });
    console.log("LoadDepends");

    LogBox.ignoreLogs(["Animated: `useNativeDriver` was not specified."]);
    LogBox.ignoreLogs(["EventEmitter.removeListener"]);

    if (Platform.OS === "web") {
      var style = document.createElement("style");
      style.innerHTML = `
      iframe {
        display: none;
      }
    `;
      document.head.appendChild(style);
    }
  }, []);

  if (!(fontLoaded && userLoaded)) {
    console.log("Loading LoadDepends");
    return (
      <>
        <ActivityIndicator color={"#555"} />
      </>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>{children}</Provider>
    </QueryClientProvider>
  );
}
