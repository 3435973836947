import * as React from "react";
import { Text, StyleSheet } from "react-native";
import { Row, ButtonIcon } from "../../components/baseUI";
import { getWordTypeName } from "./staticValuesCart";

export default function WordFamilyItem({
  item,
  id,
  editable,
  onPress,
  colors,
}) {
  return (
    <Row style={styles.itemWordFamily}>
      <Row>
        <Text style={[styles.itemWordFamilyTitle, { color: colors.textGray }]}>
          {item.word}
        </Text>
        <Text style={[styles.title, { color: colors.textGray }]}>
          {" "}
          {item.description}{" "}
        </Text>
      </Row>

      <Row>
        <Text style={styles.title}>{getWordTypeName(item.type)}</Text>
        {editable && (
          <ButtonIcon
            style={styles.itemWordFamilyBtn}
            color={colors.textGray}
            title={"  "}
            onPress={() => {
              if (onPress) onPress(id);
            }}
          />
        )}
      </Row>
    </Row>
  );
}

const styles = StyleSheet.create({
  itemWordFamily: {
    padding: 5,
    marginVertical: 5,
    marginHorizontal: 5,
    justifyContent: "space-between",
  },

  itemWordFamilyTitle: {
    fontSize: 16,
  },

  itemWordFamilyBtn: {
    fontSize: 16,
  },
});
