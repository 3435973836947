import * as React from 'react';
import { Text, TouchableOpacity } from 'react-native';

export default function IButton({ title, accessibilityLabel, style, styleButton, onPress, children }) {
  return (
    <TouchableOpacity
      styleButton={styleButton}
      onPress={onPress}
      title={title || ""}
      color={"#ffffff00"}
      accessibilityLabel={accessibilityLabel || ""}
    >
      {
        children ? <>{children}</> : <Text style={style}>{title || ""}</Text>
      }
    </TouchableOpacity>
  );
}

