import * as React from 'react';
import { View, StyleSheet } from 'react-native';

export default function Row({ children, style, justifyContent = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | undefined }) {
  return (
    <View style={[styles.row, { justifyContent: justifyContent || 'flex-start' }, style]}>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row'
  },
});
