String.prototype.toShort = function (text, maxLen = 15) {
  if (this.length > maxLen) {
    return this.substring(0, maxLen) + "...";
  } else {
    return this;
  }
};

String.prototype.includesArray = function (array, position = 0) {
  if (array) {
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (this.includes(element, position)) {
        return element
      }
    }
  } else {
    return null;
  }
  return null;
};

module.exports = {};
