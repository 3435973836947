import * as React from "react";
import { View, StyleSheet } from "react-native";

export default function Container({ children, paddingTop = 10, style }) {
  return (
    <View style={[styles.container, { paddingTop: paddingTop }, style]}>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
});
