import { Text } from "react-native";

export default ({ step, color }) => {
  let style = {
    fontFamily: "fontello",
    fontSize: 35,
    marginLeft: 10,
    marginTop: -9,
    color: color,
  };
  switch (+step + 1) {
    case 1:
      return <Text style={[style, {}]}></Text>;
    case 2:
      return <Text style={[style, {}]}></Text>;
    case 3:
      return <Text style={[style, {}]}></Text>;
    case 4:
      return <Text style={[style, {}]}></Text>;
    case 5:
      return <Text style={[style, {}]}></Text>;
    case 6:
      return <Text style={[style, {}]}></Text>;
    default:
      return <Text style={[style, {}]}>level {+step}</Text>;
  }
};
