import { memo, useRef } from "react";
import { Text, View, StyleSheet } from "react-native";
import { FlatList, ActivityIndicator } from "react-native";
import { useSelector } from "react-redux";

import {
  Box,
  Container,
  ButtonIcon,
  Button,
  Row,
  Label,
} from "../../components/baseUI";

import {
  countOfCart,
  countOfReadyCart,
} from "../../redux/todos/leitnerInfoBoxSlice";

import CartDetailsItem from "./cartDetailsItem";

import { useTemplate } from '../../context/templateContext';

const PageSearchList = ({
  data,
  fetchNextPage = () => {},
  isFetching = false,
}) => {
  const selectCountOfCart = useSelector(countOfCart);
  const selectCountOfReadyCart = useSelector(countOfReadyCart);

  //debugger;
  const ItemSeparatorView = () => {
    return (
      <View
        style={{
          height: 0.5,
          width: "100%",
          backgroundColor: "#C8C8C8",
        }}
      />
    );
  };

  const renderFooter = () => {
    const colors = useTemplate().colors;
    return (
      <View style={styles.footer}>
        {isFetching ? (
          <ActivityIndicator
            color={colors.black}
            style={{ margin: 15 }}
          />
        ) : null}
      </View>
    );
  };

  const infoBox = () => {
    const colors = useTemplate().colors;
    return (
      <Box>
        <Container>
          <Row>
            <Text style={{color:colors.black}}>
              Count :{selectCountOfCart}{"   "}
            </Text>
            <Text style={{color:colors.black}}>
              Ready :{selectCountOfReadyCart}{" "}
            </Text>
          </Row>
        </Container>
      </Box>
    );
  };
  return (
    <FlatList
      //ref={(ref) => (flatListRef = ref)}
      style={styles.list}
      data={data.items}
      renderItem={({ item }) => (
        <CartDetailsItem formData={item} query={data.query} />
      )}
      keyExtractor={(item) => `child-key-${item.id}`}
      //ItemSeparatorComponent={ItemSeparatorView}
      ListHeaderComponent={infoBox}
      ListFooterComponent={renderFooter}
      onEndReached={() => {
        fetchNextPage({ offset: 3 });
      }}
      onEndReachedThreshold={0.5}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
    />
  );
};

const styles = StyleSheet.create({
  footer: {
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  list: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 50,
  },
});

export default memo(PageSearchList);
