import * as React from 'react';
import { View, StyleSheet, StatusBar } from 'react-native';


export default function Head({ children, style }) {
  return (
    <View style={[styles.head, style]}>
      {children} 
    </View>
  );
}

const styles = StyleSheet.create({
  head: {
    height: 60 + (StatusBar.currentHeight || 0),
    paddingTop: 14 + (StatusBar.currentHeight || 5),
    paddingLeft: 13,
    paddingRight: 13,
    justifyContent: "space-between",
    flexDirection: 'row'
  },
});
