import React from "react";
import axios from "axios";
import { Text, View, StyleSheet, Image, Platform, Linking } from "react-native";
import { Page, Body, Box, ButtonIcon } from "../../components/baseUI";
import { useAuth } from "../../context/AuthContext";
import Background from "../../components/ui/Background";

export default function Login() {
  const auth = useAuth();

  function goToGoogleSingIn() {
    if (Platform.OS === "web") {
      window.open(axios.defaults.baseURL + "/auth/google/", "_parent");
    } else {
      Linking.openURL(axios.defaults.baseURL + "/auth/google/");
    }
  }

  function UserProfile() {
    return (
      <View>
        {auth.user ? (
          <View>
            <View
              style={{
                flexDirection: "column",
                alignItems: "center",
                paddingTop: 30,
              }}
            >
              <Image
                style={{
                  width: 90,
                  height: 90,
                  borderRadius: 90,
                  backgroundColor: "#a8adef",
                }}
                source={{ uri: auth.user.photo }}
              />
              <Text>{auth.user.email}</Text>

              <Text>{auth.user.displayName}</Text>
            </View>
          </View>
        ) : (
          <></>
        )}
      </View>
    );
  }

  function UserLogin() {
    console.log("auth.user", auth.user);
    //debugger;
    return (
      <View>
        {auth.user == null ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              flexDirection: "row",
              margin: 50,
            }}
          >
            <View
              style={{
                backgroundColor: "#007bff",
                padding: 10,
                borderRadius: 4,
              }}
            >
              <ButtonIcon
                title={"   Google Login"}
                color={"#FFF"}
                fontSize={32}
                onPress={() => {
                  goToGoogleSingIn();
                }}
              />
            </View>
          </View>
        ) : (
          <></>
        )}
      </View>
    );
  }

  return (
    <Page>
      <Background></Background>
      <Body>
        <Box>
          <UserProfile />
          <UserLogin />
        </Box>
      </Body>
    </Page>
  );
}

const styles = StyleSheet.create({
  headerButton: {
    fontFamily: "fontello",
    fontSize: 32,
    color: "#fff",
  },
  menu: {
    fontFamily: "fontello",
    fontSize: 20,
    color: "#444",
  },

  paragraph: {
    fontFamily: "fontello",
    textAlign: "center",
  },

  scroll: {
    height: 90,
  },

  item: {
    padding: 5,
    marginVertical: 5,
    marginHorizontal: 5,
    justifyContent: "space-between",
  },
});
