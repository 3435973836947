import * as React from "react";
import { useState, useEffect } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Linking,
} from "react-native";

import { useQueryClient, useMutation, useQuery } from "react-query";
import {
  Page,
  Body,
  Footer,
  Box,
  Container,
  Row,
  Column,
  Label,
  TextInput,
  TextArea,
  ButtonIcon,
  IButton,
  Button,
  Dialog,
  TagsInput,
} from "../../components/baseUI";

import Toast from "../../components/ui/Toast";
import Loading from "../../components/ui/Loading";
import ErrorButton from "../../components/ui/ErrorButton";
import Background from "../../components/ui/Background";

import { listTags, upsertCart, loadCart } from "../../services/cart";

import AddEditCartModal from "./addEditCartModal";
import TypesModal from "./typesModal";
import ColorModal from "./colorModal";
import WordFamilyItem from "./wordFamilyItem";
import { getWordTypeName, clipboardConvertToObject } from "./staticValuesCart";
import { useTemplate } from "../../context/templateContext";
import * as Clipboard from "expo-clipboard";

export default function AddEditCart({
  pageId,
  pageParentId,
  isModal = false,
  changeForm,
  closeOnPress,
}) {
  const colors = useTemplate().colors;
  const isAdding = +pageId === 0;
  const queryClient = useQueryClient();
  const [loadingState, setLoading] = useState(false);
  const [toastState, setToast] = useState({
    visible: false,
    message: "",
    time: 4000,
  });
  const [errorLoadACartState, setErrorLoadACartState] = useState({
    visible: false,
    message: "",
  });
  const [modalColor, setModalColor] = useState({ visible: false });
  const [modalType, setModalType] = useState({ visible: false });
  const [modalDialogDelete, setModalDialogDelete] = useState({
    visible: false,
  });
  const [modalFamilyWord, setModalFamilyWord] = useState({
    visible: false,
    value: {},
  });

  const [id, setId] = useState("");
  const [parentId, setParentId] = useState("");
  const [word, setWord] = useState("");
  const [color, setColor] = useState("");
  const [typeWord, setTypeWord] = useState("");
  const [typeWordName, setTypeWordName] = useState("Type");
  const [tags, setTags] = useState("");
  const [description, setDescription] = useState("");
  const [pronunciation, setPronunciation] = useState("");
  const [example, setExample] = useState("");
  const [relatedCards, setRelatedCards] = useState([]);

  const {
    data: formData,
    status: statusLoad,
    isSuccess: isSuccessLoad,
  } = useQuery(
    ["loadCartQuery", pageId],
    () => loadCart({ id: pageId, parentId: pageParentId }),

    {
      onSuccess: () => {
        console.log(new Date());
        setErrorLoadACartState({ ...errorLoadACartState, visible: false });
      },
      onError: (error) => {
        setToast({ message: "🤨 " + error.message, time: 2000 });
        setLoading(false);
        setErrorLoadACartState({ visible: true, message: error.message });
        Linking.openURL(axios.defaults.baseURL + "/auth/google/");
      },
    }
  );

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
    upsertCart,
    {
      onSuccess: async (result) => {
        setToast({
          message: "🤓 Your cart has been created successfully.",
          time: 2000,
        });
        setId(() => result.id);
        queryClient.setQueryData(["mutateCreate", pageId], (perverseValue) => ({
          ...perverseValue,
          ...result,
        }));
        const cart = getCart();
        let newCart = { ...result, ...cart, level: 0 };
        updateList(newCart);
        if (changeForm) changeForm({ isAdding, item: newCart });
      },
      onError: (err) => {
        setToast({ message: "🤨 " + err.message, time: 2000 });
      },
    }
  );

  const { mutate: mutateEdit, isLoading: isLoadingEdit } = useMutation(
    upsertCart,
    {
      onSuccess: async (result, b) => {
        //debugger;
        setToast({
          visible: true,
          message: "🤓 Your cart has been saved successfully.",
          time: 2000,
        });

        setId(() => result.id);
        queryClient.setQueryData(["mutateEdit", pageId], (perverseValue) => ({
          ...perverseValue,
          ...result,
        }));
        const cart = getCart();
        let newCart = { ...result, ...cart };
        //debugger;
        updateList(newCart);
        if (changeForm) changeForm({ isAdding, item: newCart });
      },
      onError: (err) => {
        setToast({ message: "🤨 " + err.message, time: 2000 });
        //if (isModal && changeForm) changeForm(false);
      },
    }
  );

  useEffect(() => {
    if (statusLoad === "loading") {
      setLoading(true);
    } else if (statusLoad === "success") {
      if (pageId) {
        setId(formData.id);
        setWord(formData.word);
        setColor(formData.color);
        setTypeWord(formData.type);
        setTypeWordName(getWordTypeName(formData.type, ",").toShort());
        setTags(formData.tags);
        setDescription(formData.description);
        setPronunciation(formData.pronunciation);
        setExample(formData.example);
        setRelatedCards(formData.relatedCards || []);
      }
      setParentId(formData.parentId);
      setLoading(false);
    }
  }, [formData, statusLoad]);

  const getCart = () => {
    return {
      parentId,
      word: word.trim(),
      color,
      type: typeWord || 0,
      tags: tags.trim().toLowerCase().replace(/# #/g, "#"),
      description,
      pronunciation,
      example,
      hasStar: formData.hasStar || 0,
      hasFavourite: formData.hasFavourite || 0,
    };
  };

  const btnSave = () => {
    const cart = getCart();
    if (cart.word.length < 1) {
      return;
    }

    if (id) {
      cart.id = id;
      mutateEdit({ cart });
    } else {
      mutateCreate({ cart });
    }
  };

  const btnCancel = () => {
    setLoading(false);
    if (isModal) {
      if (closeOnPress) closeOnPress({ isAdding });
    } else {
      if (closeOnPress) closeOnPress({ isAdding });
    }
  };

  const updateList = (newCart) => {
    let queryList = queryClient.queryCache.queries.filter(
      (m) =>
        m.queryHash.indexOf(`"listCartQuery"`) !== -1 ||
        m.queryHash.indexOf(`"searchCartQuery"`) !== -1
    );
    for (var i = 0; i < queryList.length; i++) {
      queryClient.setQueryData(queryList[i].queryKey, (perverseList) => {
        let result = { ...perverseList };
        if (isAdding) {
          result.pages[0].result.data = [
            newCart,
            ...result.pages[0].result.data,
          ];
        } else {
          for (var p = 0; p < result.pages.length; p++) {
            let index = result.pages[p].result.data.findIndex(
              (m) => m.id == newCart.id
            );
            if (index !== -1) {
              result.pages[p].result.data[index] = {
                ...result.pages[p].result.data[index],
                ...newCart,
              };
              return result;
            }
          }
        }
        return result;
      });
    }
  };

  const importCart = async () => {
    let clipboardContent = await Clipboard.getStringAsync();
    let obj = clipboardConvertToObject(clipboardContent);
    setWord(obj.word);
    setTypeWord(obj.type);
    setTags(obj.tags);
    setDescription(obj.description);
    setPronunciation(obj.pronunciation);
    setExample(obj.example);
    setTypeWordName(getWordTypeName(obj.type, ",").toShort());
  };

  return (
    <Page>
      {!isModal && <Background></Background>}
      <Body>
        <Box>
          <Container>
            <Column>
              <Row justifyContent={"space-between"}>
                <Row>
                  <Label>Word </Label>
                  <ButtonIcon
                    title={"    "}
                    fontSize={15}
                    style={{ paddingTop: 7 }}
                    color={colors.textGray}
                    onPress={() => {
                      setModalColor({ ...modalColor, visible: true });
                    }}
                  />
                </Row>

                <Row>
                  <IButton
                    onPress={() => {
                      setModalType({ ...modalType, visible: true });
                    }}
                  >
                    <Row>
                      <Label
                        style={[
                          styles.typeBtnLabel,
                          { color: colors.textGray },
                        ]}
                      >{` ${typeWordName || "Type"} `}</Label>
                      <Label style={styles.categoryIcon}> </Label>
                    </Row>
                  </IButton>

                  {!id ? (
                    <ButtonIcon
                      title={"      "}
                      fontSize={17}
                      style={{ paddingTop: 3 }}
                      color={colors.textGray}
                      onPress={async () => {
                        await importCart();
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Row>
              </Row>

              <TextInput
                colors={colors}
                placeholder={"Word"}
                value={word}
                onChangeText={(text) => {
                  setWord(text);
                }}
              />
            </Column>
          </Container>
        </Box>

        <Box>
          <Container>
            <Column>
              <Label>Tags</Label>
              <TagsInput
                placeholder={"Tags"}
                value={tags}
                colors={colors}
                onChangeText={(tags) => {
                  setTags(tags);
                }}
                onSearch={async ({ text }) => {
                  let list = await listTags({ q: text });
                  return list;
                }}
                itemComponents={(item, wordSelected, setList) => {
                  return (
                    <View style={{}}>
                      <TouchableOpacity
                        //color={"#ffffff00"}
                        onPress={() => {
                          var newTags =
                            tags.substring(0, wordSelected.start) +
                            item.title.replace(/ /g, "_") +
                            tags.substring(wordSelected.end);
                          //debugger;
                          setTags(newTags.trimStart());
                          setList(false);
                        }}
                      >
                        <View
                          style={{
                            height: 45,
                            borderRadius: 30,
                            backgroundColor: "#fff",
                            padding: 5,
                            marginLeft: 10,
                            marginTop: 5,
                            borderColor: "#0d9d59",
                            borderWidth: 1,
                          }}
                        >
                          <Text
                            style={[
                              {
                                fontSize: 20,
                                textAlign: "center",
                                color: "#333",
                              },
                            ]}
                          >
                            {" " + item.title + " "}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  );
                }}
              />

              {/* 
              <IButton onPress={() => { setModalCategoryTypes(true) }} >
                <Row style={styles.categoryRow}>
                  <Label style={styles.categoryIcon}></Label>
                  <Label style={styles.categoryLabel}> Category </Label>
                </Row>
              </IButton> 
              */}

              <Label>Description</Label>
              <TextInput
                colors={colors}
                placeholder={"Description"}
                value={description}
                onChangeText={(text) => {
                  setDescription(text);
                }}
              />
              <Label>Pronunciation</Label>
              <TextInput
                colors={colors}
                placeholder={"Pronunciation"}
                value={pronunciation}
                onChangeText={(text) => {
                  setPronunciation(text);
                }}
              />
              <Label>Example</Label>
              <TextArea
                colors={colors}
                placeholder={"Example"}
                value={example}
                onChangeText={(text) => {
                  setExample(text);
                }}
              />
            </Column>

            {id ? (
              <Column>
                <Row justifyContent={"space-between"}>
                  <Label>Word Family</Label>
                  <ButtonIcon
                    title={""}
                    color={colors.textGray}
                    fontSize={22}
                    onPress={() => {
                      setModalFamilyWord({
                        value: { pageId: "", pageParentId: id },
                        visible: true,
                      });
                    }}
                  />
                </Row>
                <View>
                  {relatedCards &&
                    relatedCards.map((item, i) => {
                      return (
                        <WordFamilyItem
                          colors={colors}
                          editable={true}
                          item={item}
                          id={item.id}
                          key={i}
                          onPress={(itemId) => {
                            setModalFamilyWord({
                              value: { pageId: itemId, pageParentId: id },
                              visible: true,
                            });
                          }}
                        />
                      );
                    })}
                </View>
              </Column>
            ) : (
              <></>
            )}
          </Container>
        </Box>

        <ColorModal
          value={color}
          visible={modalColor.visible}
          onRequestClose={(closeType, value) => {
            if (closeType === "Save") {
              setModalColor({ visible: false });
              setColor(value);
            }
          }}
        />

        <TypesModal
          value={typeWord}
          visible={modalType.visible}
          onRequestClose={(closeType, value) => {
            if (closeType === "Save") {
              setTypeWord(value.type);
              setTypeWordName(getWordTypeName(value.type).toShort());
            }
            setModalType({ ...modalType, visible: false });
          }}
        />

        <AddEditCartModal
          value={modalFamilyWord.value}
          visible={modalFamilyWord.visible}
          onRequestClose={(closeType, value) => {
            setModalFamilyWord({ value: { pageId: "" }, visible: false });
          }}
          changeForm={({ item, isAdding }) => {
            setModalFamilyWord({ ...modalFamilyWord, visible: false });
            if (isAdding) {
              setRelatedCards([...relatedCards, item]);
            } else {
              let newRelatedCards = [...relatedCards];
              let index = newRelatedCards.findIndex((m) => m.id === item.id);
              if (index != -1) {
                newRelatedCards[index] = item;
                setRelatedCards(newRelatedCards);
              }
            }
          }}
          closeOnPress={() => {
            debugger;
            setModalFamilyWord({ value: { pageId: "" }, visible: false });
          }}
        />

        <Dialog
          visible={modalDialogDelete.visible}
          text="Are you sure to delete?"
          OkText="OK"
          CancelText="Cancel"
          onRequestClose={() => {
            setModalDialogDelete({ ...modalDialogDelete, visible: false });
          }}
        />
      </Body>

      <Footer>
        <View style={{ flex: 1, height: 70 }}>
          <Button
            title={isModal ? "Discord" : "Cancel"}
            color={colors.discordBtn}
            textColor={colors.textWhite}
            onPress={btnCancel}
          />
        </View>

        <View style={{ flex: 1, height: 70 }}>
          <Button
            title={"Save"}
            color={colors.saveBtn}
            textColor={colors.textWhite}
            onPress={btnSave}
            loading={isLoadingEdit || isLoadingCreate}
            enable={isSuccessLoad}
          />
        </View>
      </Footer>

      <Loading visible={loadingState} />

      <ErrorButton
        visible={errorLoadACartState.visible}
        message={errorLoadACartState.message}
        onPress={() => {
          queryClient.refetchQueries(["loadCartQuery", pageId]);
          setErrorLoadACartState({ ...errorLoadACartState, visible: false });
          setLoading(true);
        }}
      />

      <Toast
        {...toastState}
        onHide={() => {
          setToast({ ...toastState, visible: false });
        }}
      />
    </Page>
  );
}

const styles = StyleSheet.create({
  headerButton: {
    fontFamily: "fontello",
    fontSize: 32,
    color: "#fff",
  },

  typeBtnIcon: {
    fontFamily: "fontello",
    fontSize: 22,
  },

  typeBtnRow: {
    paddingTop: 12,
  },

  typeBtnLabel: {
    fontSize: 18,
  },

  categoryRow: {
    paddingTop: 12,
  },

  categoryIcon: {
    fontFamily: "fontello",
    fontSize: 20,
  },

  categoryLabel: {
    color: "#444",
  },

  typesCheckBox: {
    paddingTop: 5,
  },

  textInput: {
    backgroundColor: "#fff",
    borderColor: "#C4C4C4",
    borderWidth: 1,
    borderRadius: 5,
    height: 40,
    paddingLeft: 4,
  },

  textInput_text: {
    color: "#111",
  },

  textInput_placeholder: {
    color: "#A8A3B4",
  },

  box: {
    position: "absolute",
    backgroundColor: "#EEE",
    borderColor: "#C4C4C4",
    borderWidth: 1,
    borderRadius: 5,
    minHeight: 200,
    minWidth: 50,

    zIndex: 10000, // works on ios
    elevation: 10000, // works on android
    display: "flex",
  },
});
