import React, { useRef, useEffect, useState } from 'react';
import { Text, View, StyleSheet, Animated, Dimensions } from "react-native";
import { ButtonIcon } from '../baseUI';


export default function ErrorButton({ visible = false, onPress = () => { }, message = "Something was wrong." }) {
    const showAnimated = useRef(new Animated.Value(0)).current;
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        if (visible) {
            setDisplay(true);
            Animated.parallel([
                Animated.timing(showAnimated, {
                    toValue: 1,
                    duration: 500,
                }),
            ]).start(() => { });
        } else {
            Animated.parallel([
                Animated.timing(showAnimated, {
                    toValue: 0,
                    duration: 500,
                }),
            ]).start(() => {
                setDisplay(false);
            });
        }
    }, [visible]);


    return (
        <Animated.View style={[styles.centeredView, { opacity: showAnimated, display: !display ? 'none' : 'flex' }]}>
            <View style={[styles.modalView]}>
                <View>
                    <Text style={[styles.text]}>{message}</Text>
                </View>
                <ButtonIcon
                    title={" ⟳ Try "}
                    color={"#fff"}
                    fontSize={22}
                    style={{ backgroundColor: '#6fa9d0', borderRadius: 5 }}
                    onPress={() => { if (onPress) onPress() }}
                />
            </View>
        </Animated.View>
    );
}


const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: 'center',
        top: (Dimensions.get('window').height - 280),
        minHeight: '10%',
        width: '100%',
        position: 'absolute',
    },
    modalView: {
        "shadowOffset": {
            "width": 1,
            "height": 1
        },
        "shadowOpacity": 0.5,
        "shadowRadius": 17.084375,

        flexDirection: "row",
        margin: 16,
        backgroundColor: "white",
        borderRadius: 8,
        shadowColor: "#000",
        elevation: 5,
        overflow: "hidden",
        width: "90%",
        paddingTop: 9,
        justifyContent: 'space-around',
        minHeight: 40
    },
    text: {
        fontSize: 18
    },
});
