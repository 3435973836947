import { createSlice } from "@reduxjs/toolkit";

export const leitnerInfoBoxSlice = createSlice({
  name: "leitnerInfoBoxSlice",
  initialState: {
    countOfCart: 0,
    countOfReadyCart: 0,
  },
  reducers: {
    setCountOfCart: (state, { payload }) => {
      state.countOfCart = payload;
    },
    setCountOfReadyCart: (state, { payload }) => {
      state.countOfReadyCart = payload;
    },
    decrementCountOfCart: (state) => {
      state.countOfCart -= 1;
    },
    decrementCountOfReadyCart: (state) => {
      state.countOfReadyCart -= 1;
    },
    incrementCountOfCart: (state) => {
      state.countOfCart += 1;
    },
    incrementCountOfReadyCart: (state) => {
      state.countOfReadyCart += 1;
    },
  },
});

export const {
  setCountOfCart,
  setCountOfReadyCart,
  decrementCountOfCart,
  decrementCountOfReadyCart,
  incrementCountOfCart,
  incrementCountOfReadyCart,
} = leitnerInfoBoxSlice.actions;

export const countOfCart = (state) => state.leitnerInfoBoxSlice.countOfCart;
export const countOfReadyCart = (state) =>
  state.leitnerInfoBoxSlice.countOfReadyCart;

export default leitnerInfoBoxSlice.reducer;
