import React, {
  createContext,
  useState,
  useContext,
  useLayoutEffect,
} from "react";
import {
  updateUserProfile,
  clearUserProfile,
  getUserProfile,
} from "../services/authorization";

export const AuthContext = createContext();
import { ActivityIndicator } from "react-native";

export function useAuth() {
  return useContext(AuthContext);
}

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useLayoutEffect(() => {
    loadUser()
      .then((usedLoaded) => {
        console.log("loadUser 1"); 
        setUser(usedLoaded);
      })
      .catch(() => {
        console.log("loadUser 11 catch"); 
      });

    updateUserProfile()
      .then((usedLoaded) => {
        console.log("updateUserProfile 2"); 
        setUser(usedLoaded);
      })
      .catch(() => {
        console.log("updateUserProfile 22 catch"); 
      });
  }, []);

  const loadUser = () => {
    return new Promise((resolve, reject) => {
      getUserProfile()
        .then((res) => {
          setUser(res);
          resolve(res);
        })
        .catch(() => {
          reject();
        });
    });
  };

  const updateUser = (callback) => {
    updateUserProfile()
      .then((res) => {
        setUser(res);
        if (callback) callback(res);
      })
      .catch(() => {
        if (callback) callback(null);
      });
  };

  const signIn = (username, password, callback) => {};

  const signOut = (callback) => {
    setUser(null);
    clearUserProfile().then(() => {
      callback();
    });
  };

  const value = { user, signIn, signOut, loadUser, updateUser };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
