import * as React from 'react'; 
import * as RootNavigation from '../../components/ui/Navigator';
import Login from '.';


export default function LoginScreen({ navigation }) {

    return (
        <Login/> 
    );
}
 
